import { toast } from 'react-toastify'
import i18next from 'i18n'
import moment from 'utils/moment'
import axios from 'utils/axios'
import { getAuthentificationHeader, isResultSuccessful } from 'utils/api'
import { TransactionType } from 'types/transactions'

/**
 * Returns the invoice url based on the transaction type
 * @param {String} type
 * @param {String} invoiceId
 */
function getInvoiceUrl (type, invoiceId) {
  const lowerCasedType = type.toLowerCase()
  if (lowerCasedType === TransactionType.STRIPE) {
    return `/stripe/invoices/${invoiceId}`
  }
  if (lowerCasedType === TransactionType.PAYPAL) {
    return `/paypal/invoices/${invoiceId}`
  }
  return undefined
}

/**
 * Retrieves the `Invoice` with specific id.
 * @param {String} id The id of the invoice.
*/
export async function getInvoice (transactionType, invoiceId, authToken) {
  const url = `${getInvoiceUrl(transactionType, invoiceId)}`
  const result = await axios.get(url, {
    headers: getAuthentificationHeader(authToken),
  })
  if (isResultSuccessful(result)) {
    const text = result.data
    return { ...result, text, okay: true }
  } else {
    return {
      okay: false,
      statusCode: result.status,
      message: result.statusText,
    }
  }
}

export function downloadFile (fileName, data, options) {
  const dataType = typeof data
  let fileExtension
  let fileType
  let actualDataToDownload

  if (dataType === 'object') {
    fileType = 'data:application/json'
    fileExtension = 'txt'
    actualDataToDownload = JSON.stringify(data)
  } else if (dataType === 'string' || dataType === 'number') {
    fileType = (options && options.type) || 'text/pdf;charset=utf8;'
    fileExtension = (options && options.extension) || 'pdf'
    actualDataToDownload = data
  } else {
    fileType = 'text/pdf;charset=utf8;'
    fileExtension = 'pdf'
    actualDataToDownload = ''
  }
  const blob = new Blob([actualDataToDownload], { type: fileType })

  // create hidden link
  const element = document.createElement('a')
  document.body.appendChild(element)
  element.setAttribute('href', URL.createObjectURL(blob))
  element.setAttribute('download', `${fileName}.${fileExtension}`)
  element.style.display = ''

  element.click()

  document.body.removeChild(element)
}

export async function downloadInvoice (transaction, token) {
  const result = await getInvoice(
    transaction.transactionType,
    transaction.id,
    token,
  )
  const text = result.text
  if (isResultSuccessful(result) && typeof text === 'string') {
    const formattedDate = moment(transaction.created).format('DD-MM-YYYY')
    const fileName = `invoice__${formattedDate}`
    downloadFile(fileName, text)
  } else {
    toast.error(i18next.t('invoice.downloadError'))
  }
}
/**
 * Checks if the given invoice is failed
 * @param {Invoice} invoice
 * @returns {Boolean}
 */
export const isInvoiceFailed = invoice => {
  return invoice.status === 'open' && invoice.charge && invoice.charge.status === 'failed'
}
