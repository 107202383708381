import { createReducer } from 'reduxsauce'
import Cookies from 'js-cookie'
// Project deps
import { getLoggedInAsUser, setToken } from 'utils/axios'
import { mapById, removeById } from 'utils/list'
import { convertRawCompanyPermission, convertRawProjectPermission } from 'types/permissions'
import { convertRawUser } from 'types/users'
import { cookieName } from 'utils/cookies'
// Local deps
import { INITIAL_STATE } from './initialState'
import { UsersTypes } from './actions'
import { setTimezone } from './utils'
import { isUserPLS } from 'utils/company'
import { convertRawLog } from 'types/logs'

export const getUsersLoading = state =>
  state.merge({
    getUsersIsLoading: true,
    getUsersErrorMessage: '',
  })

export const getUsersSuccess = (state, { users, nonCompanyUsers }) =>
  state.merge({
    users: users.map(convertRawUser),
    nonCompanyUsers: nonCompanyUsers.map(convertRawUser),
    plsUsers: users.filter(isUserPLS).map(convertRawUser),
    getUsersIsLoading: false,
    getUsersErrorMessage: null,
  })

export const getUsersFailure = (state, { errorMessage }) =>
  state.merge({
    users: [],
    getUsersIsLoading: false,
    getUsersErrorMessage: errorMessage,
  })

export const getProjectsStatisticLoading = state => state.merge({
  getProjectsStatisticIsLoading: true,
})

export const getProjectsStatisticSuccess = (state, { projectsStatistic }) =>
  state.merge({
    projectsStatistic,
    getProjectsStatisticIsLoading: false,
  })

export const getProjectsStatisticFailure = (state, { errorMessage }) => state.merge({
  getProjectsStatisticIsLoading: false,
})

export const getUserLoading = state =>
  state.merge({
    getUserIsLoading: true,
    getUserErrorMessage: '',
  })

export const getUserSuccess = (state, { user }) =>
  state.merge({
    user,
    getUserIsLoading: false,
    getUserErrorMessage: null,
  })

export const getUserFailure = (state, { errorMessage }) =>
  state.merge({
    user: null,
    getUserIsLoading: false,
    getUserErrorMessage: errorMessage,
  })

export const getMeLoading = state =>
  state.merge({
    getMeIsLoading: true,
    getMeErrorMessage: '',
  })

export const getMeSuccess = (state, { user, permissions }) => {
  const userTimeZone = user.time_zone
  if (userTimeZone) {
    setTimezone(userTimeZone)
  }
  return state.merge({
    user: convertRawUser(user),
    loggedUserPermissions: permissions,
    getMeIsLoading: false,
    getMeErrorMessage: null,
  })
}

export const getMeFailure = (state, { errorMessage }) =>
  state.merge({
    user: null,
    getMeIsLoading: false,
    getMeErrorMessage: errorMessage,
  })

export const getActiveUserLoading = state =>
  state.merge({
    getActiveUserIsLoading: true,
    getActiveUserErrorMessage: '',
  })

export const getActiveUserSuccess = (state, { user }) =>
  state.merge({
    activeUser: convertRawUser(user),
    getActiveUserIsLoading: false,
    getActiveUserErrorMessage: null,
  })

export const setActiveUserSubscription = (state, { subscription }) => {
  return state.merge({
    // ...state,
    activeUser: {
      ...state.get('activeUser'),
      billingAgreement: subscription,
    },
  })
}

export const setActiveUserGrants = (state, { grants }) => {
  return state.merge({
    // ...state,
    activeUser: {
      ...state.get('activeUser'),
      grants,
    },
  })
}

export const getActiveUserFailure = (state, { errorMessage }) => {
  return state.merge({
    activeUser: {},
    getActiveUserIsLoading: false,
    getActiveUserErrorMessage: errorMessage,
  })
}

export const createUserLoading = state =>
  state.merge({
    createUserIsLoading: true,
    createUserErrorMessage: null,
  })

export const createUserSuccess = state =>
  state.merge({
    createUserIsLoading: false,
    createUserErrorMessage: null,
  })

export const createUserFailure = (state, { errorMessage }) =>
  state.merge({
    createUserIsLoading: false,
    createUserErrorMessage: errorMessage,
  })

// login
export const loginLoading = state =>
  state.merge({
    loginIsLoading: true,
    loginErrorMessage: null,
  })

export const loginSuccess = (state, { token, user, redirectUrl }) => {
  setToken(token)
  return state.merge({
    externalRedirectUrl: redirectUrl,
    passwordNotSet: false,
    token,
    user,
    loginIsLoading: false,
    loginErrorMessage: null,
    twoFactorLogin: false,
    tempToken: null,
  })
}

export const loginFailure = (state, { errorMessage, passwordNotSet, allowedToLoginAt }) => {
  localStorage.removeItem('logged_as')
  return state.merge({
    ...(passwordNotSet && { passwordNotSet }),
    token: null,
    user: null,
    loginIsLoading: false,
    loginErrorMessage: errorMessage,
    allowedToLoginAt: allowedToLoginAt,
  })
}

// login as
export const loginAsLoading = state =>
  state.merge({
    loginIsLoading: true,
    loginErrorMessage: null,
  })

export const loginAsSuccess = (state, { token, user, loggedAs }) => {
  setToken(token)
  return state.merge({
    passwordNotSet: false,
    token,
    user,
    loginIsLoading: false,
    loginErrorMessage: null,
    twoFactorLogin: false,
    tempToken: null,
    loggedAs: loggedAs,
  })
}

export const loginAsFailure = (state, { errorMessage, passwordNotSet }) => {
  return state.merge({
    ...(passwordNotSet && { passwordNotSet }),
    token: null,
    user: null,
    loginIsLoading: false,
    loginErrorMessage: errorMessage,
  })
}
// login using two-factor auth
export const twoFactorLoginLoading = state =>
  state.merge({
    twoFactorLoginIsLoading: true,
    loginErrorMessage: null,
  })

export const twoFactorLoginSuccess = (state, { token, user, redirectUrl }) => {
  setToken(token)
  return state.merge({
    externalRedirectUrl: redirectUrl,
    passwordNotSet: false,
    token,
    user,
    loginIsLoading: false,
    twoFactorLoginIsLoading: false,
    loginErrorMessage: null,
    twoFactorLogin: false,
    tempToken: null,
  })
}

export const twoFactorLoginFailure = (state, { errorMessage, isTokenExpired }) => {
  return state.merge({
    token: null,
    user: null,
    twoFactorLogin: !isTokenExpired,
    loginIsLoading: false,
    twoFactorLoginIsLoading: false,
    loginErrorMessage: errorMessage,
  })
}

export const setTwoFactorLogin = (state, { temp_token }) => {
  return state.merge({
    twoFactorLogin: true,
    tempToken: temp_token,
  })
}

export const registerLoading = state =>
  state.merge({
    registerIsLoading: true,
    registerErrorMessage: null,
  })

export const registerSuccess = (state, { user, createdByUser }) => {
  if (!createdByUser) localStorage.removeItem('dropboxToken')
  return state.merge({
    registerIsLoading: false,
    registerErrorMessage: null,
    users: [
      convertRawUser(user),
      ...(state.get('users') || []),
    ],
  })
}

export const registerFailure = (state, { errorMessage }) =>
  state.merge({
    registerIsLoading: false,
    registerErrorMessage: errorMessage,
  })

export const logoutLoading = state =>
  state.merge({
    logoutIsLoading: true,
    logoutErrorMessage: null,
  })

export const logoutSuccess = (state, { url }) => {
  setToken(null)
  Cookies.remove(cookieName, { path: '/', domain: '.phoenixlidar.com' })
  localStorage.removeItem('dropboxToken')
  localStorage.removeItem('logged_as')
  return state.merge({
    passwordNotSet: false,
    token: null,
    user: null,
    logoutIsLoading: false,
    logoutErrorMessage: null,
  })
}

export const logoutFailure = (state, { errorMessage }) =>
  state.merge({
    token: null,
    user: null,
    logoutIsLoading: false,
    logoutErrorMessage: errorMessage,
  })

// request recovery
export const requestRecoveryLoading = state =>
  state.merge({
    requestRecoveryIsLoading: true,
    requestRecoveryErrorMessage: null,
    requestRecoveryIsSuccess: false,
  })

export const requestRecoverySuccess = state => {
  return state.merge({
    passwordNotSet: false,
    requestRecoveryIsLoading: false,
    requestRecoveryErrorMessage: null,
    requestRecoveryIsSuccess: true,
  })
}

export const requestRecoveryFailure = (state, { errorMessage }) => {
  return state.merge({
    requestRecoveryIsLoading: false,
    requestRecoveryErrorMessage: errorMessage,
    requestRecoveryIsSuccess: false,
  })
}

// recover
export const recoverLoading = state =>
  state.merge({
    recoverIsLoading: true,
    recoverErrorMessage: null,
  })

export const recoverSuccess = state => {
  return state.merge({
    recoverIsSuccess: true,
    recoverIsLoading: false,
    recoverErrorMessage: null,
  })
}

export const recoverFailure = (state, { errorMessage }) => {
  return state.merge({
    recoverIsLoading: false,
    recoverErrorMessage: errorMessage,
  })
}

// confirm email
export const confirmEmailLoading = state =>
  state.merge({
    confirmEmailIsLoading: true,
    confirmEmailErrorMessage: null,
  })

export const confirmEmailSuccess = state => {
  return state.merge({
    confirmEmailIsSuccess: true,
    confirmEmailIsLoading: false,
    confirmEmailErrorMessage: null,
  })
}

export const confirmEmailFailure = (state, { errorMessage }) => {
  return state.merge({
    confirmEmailIsLoading: false,
    confirmEmailIsSuccess: false,
    confirmEmailErrorMessage: errorMessage,
  })
}

// send confirm email
export const sendConfirmEmailLoading = state =>
  state.merge({
    sendConfirmEmailIsLoading: true,
    sendConfirmEmailErrorMessage: null,
  })

export const sendConfirmEmailSuccess = state => {
  return state.merge({
    sendConfirmEmailIsSuccess: true,
    sendConfirmEmailIsLoading: false,
    sendConfirmEmailErrorMessage: null,
  })
}

export const sendConfirmEmailFailure = (state, { errorMessage }) => {
  return state.merge({
    sendConfirmEmailIsLoading: false,
    sendConfirmEmailIsSuccess: false,
    sendConfirmEmailErrorMessage: errorMessage,
  })
}

export const deleteUserLoading = state =>
  state.merge({
    deleteUserIsLoading: true,
    deleteUserErrorMessage: '',
  })

export const deleteUserSuccess = (state, { userId }) => {
  return state.merge({
    users: removeById(userId, state.get('users')),
    deleteUserIsLoading: false,
    deleteUserErrorMessage: null,
  })
}

export const deleteUserFailure = (state, { errorMessage }) =>
  state.merge({
    deleteUserIsLoading: false,
    deleteUserErrorMessage: errorMessage,
  })

export const updateUserLoading = state =>
  state.merge({
    updateUserSucceed: false,
    updateUserIsLoading: true,
    updateUserErrorMessage: '',
  })

export const updateUserSuccess = (state, { user }) => {
  const convertedUser = convertRawUser(user)
  const loggedUser = state.get('user')
  const activeUser = state.get('activeUser')
  const users = state.get('users') || []
  const update = {
    updateUserIsLoading: false,
    updateUserErrorMessage: null,
    updateUserSucceed: true,
    user: (loggedUser && loggedUser.id === user.id) ? convertedUser : loggedUser,
    activeUser: (activeUser && activeUser.id === user.id) ? convertedUser : activeUser,
    users: mapById(user.id, users, () => convertedUser),
  }
  if (activeUser && activeUser.id === user.id) {
    const userTimeZone = user.time_zone
    if (userTimeZone) {
      setTimezone(userTimeZone)
    }
  }

  return state.merge(update)
}

export const updateUserFailure = (state, { errorMessage }) =>
  state.merge({
    updateUserSucceed: false,
    updateUserIsLoading: false,
    updateUserErrorMessage: errorMessage,
  })

export const setAcceptTermsFormOpen = (state, { open }) =>
  state.merge({
    acceptTermsFormOpen: open,
  })

export const getSubscriptionsLoading = (state, { userId }) => {
  return state.merge({
    subscriptionsByUserLoading: {
      ...state.get('subscriptionsByUserLoading'),
      [userId]: true,
    },
  })
}

export const getSubscriptionsSuccess = (state, { userId, subscriptions }) => {
  return state.merge({
    subscriptionsByUser: {
      ...state.get('subscriptionsByUser'),
      [userId]: subscriptions,
    },
    subscriptionsByUserLoading: {
      ...state.get('subscriptionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const getSubscriptionsFailure = (state, { userId }) => {
  return state.merge({
    subscriptionsByUserLoading: {
      ...state.get('subscriptionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const changePasswordLoading = state => state.merge({})
export const changePasswordSuccess = state => state.merge({})
export const changePasswordFailure = state => state.merge({})

export const getUserPermissionsLoading = (state, { userId }) => {
  return state.merge({
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: true,
    },
  })
}

export const getUserPermissionsSuccess = (state, { userId, permissions }) => {
  return state.merge({
    permissionsByUser: {
      ...state.get('permissionsByUser'),
      [userId]: [
        ...permissions.company_permissions.map(convertRawCompanyPermission),
        ...permissions.project_permissions.map(convertRawProjectPermission),
      ],
    },
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const getUserPermissionsFailure = (state, { userId }) => {
  return state.merge({
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const getTwoFactorAuthLoading = (state, { userId }) => state.merge({
  qrIsLoading: {
    ...state.get('qrIsLoading'),
    [userId]: true,
  },
})
export const getTwoFactorAuthSuccess = (state, { userId, qr, key }) => state.merge({
  qr: {
    ...state.get('qr'),
    [userId]: [qr.replace('scale(5)', 'scale(4)'), key],
  },
  qrIsLoading: {
    ...state.get('qrIsLoading'),
    [userId]: false,
  },
})
export const getTwoFactorAuthFailure = (state, { userId }) => state.merge({
  qrIsLoading: {
    ...state.get('qrIsLoading'),
    [userId]: false,
  },
})

export const activateTwoFactorAuthLoading = (state, { userId }) => state.merge({
  activateTwoFactorAuthIsLoading: {
    ...state.get('activateTwoFactorAuthIsLoading'),
    [userId]: true,
  },
})
export const activateTwoFactorAuthSuccess = (state, { userId, user }) => state.merge({
  activeUser: user,
  activateTwoFactorAuthIsLoading: {
    ...state.get('activateTwoFactorAuthIsLoading'),
    [userId]: false,
  },
})
export const activateTwoFactorAuthFailure = (state, { userId }) => state.merge({
  activateTwoFactorAuthIsLoading: {
    ...state.get('activateTwoFactorAuthIsLoading'),
    [userId]: false,
  },
})

export const deactivateTwoFactorAuthLoading = (state, { userId }) => state.merge({
  deactivateTwoFactorAuthIsLoading: {
    ...state.get('deactivateTwoFactorAuthIsLoading'),
    [userId]: true,
  },
})
export const deactivateTwoFactorAuthSuccess = (state, { userId, user }) => state.merge({
  activeUser: user,
  deactivateTwoFactorAuthIsLoading: {
    ...state.get('deactivateTwoFactorAuthIsLoading'),
    [userId]: false,
  },
})
export const deactivateTwoFactorAuthFailure = (state, { userId }) => state.merge({
  deactivateTwoFactorAuthIsLoading: {
    ...state.get('deactivateTwoFactorAuthIsLoading'),
    [userId]: false,
  },
})

export const getUserDBLogsSuccess = (state, { userId, logs, pagination }) => {
  return state.merge({
    getUserDBLogsLoading: false,
    getUserDBLogsPagination: {
      ...state.get('getUserDBLogsPagination'),
      [userId]: pagination,
    },
    userLogs: {
      ...state.get('userLogs'),
      [userId]: logs.map(convertRawLog),
    },
  })
}
export const getUserDBLogsLoading = state => state.merge({
  getUserDBLogsLoading: true,
})
export const getUserDBLogsFailure = state => state.merge({
  getUserDBLogsLoading: false,
})

// Get new user product releases
export const getUserReleasesLoading = state =>
  state.merge({
    releasesIsLoading: true,
  })

export const getUserReleasesSuccess = (state, { releases }) => {
  const releasesByProduct = releases.reduce((all, release) => {
    const { products = [] } = release
    return products.reduce((all1, product) => ({
      ...all1,
      [product.name]: [...(all1[product.name] || []), release],
    }), all)
  }, {})
  const newReleases = Object.keys(releasesByProduct).map(productName => {
    const releases = releasesByProduct[productName]
    return {
      name: productName,
      releases: releases,
      changes: releases.reduce((all, release) => all + (release.filtered_changes || []).length, 0),
    }
  })
  const totalReleases = newReleases.reduce((all, release) => all + release.releases.length, 0)
  return state.merge({
    releases: newReleases,
    totalReleases,
    releasesIsLoading: false,
  })
}

export const getUserReleasesFailure = (state, { errorMessage }) =>
  state.merge({
    releases: [],
    releasesIsLoading: false,
  })

export const setUserSeenReleasesLoading = state => state.merge({})
export const setUserSeenReleasesSuccess = (state, { productName, numberOfReleases }) => {
  return state.merge({
    releases: state.get('releases').filter(releasesByProduct => releasesByProduct.name.toLowerCase() !== productName),
    totalReleases: state.get('totalReleases') - numberOfReleases,
  })
}
export const setUserSeenReleasesFailure = (state, { errorMessage }) => state.merge({})

export const getUserProjectsLoading = (state, { userId }) => state.merge({
  isGetUserProjectsLoading: {
    ...state.get('isGetUserProjectsLoading'),
    [userId]: true,
  },
})
export const getUserProjectsSuccess = (state, { userId, projects }) => state.merge({
  isGetUserProjectsLoading: {
    ...state.get('isGetUserProjectsLoading'),
    [userId]: false,
  },
  projectsByUser: {
    ...state.get('projectsByUser'),
    [userId]: projects,
  },
})
export const getUserProjectsFailure = (state, { userId }) => state.merge({
  isGetUserProjectsLoading: {
    ...state.get('isGetUserProjectsLoading'),
    [userId]: false,
  },
})

export const rehydrate = (state, { payload, token }) => {
  const loggedAs = getLoggedInAsUser()
  if (payload) {
    return state.merge({
      ...INITIAL_STATE.toObject(),
      token,
      user: payload.users.get('user'),
      loggedAs: loggedAs,
    })
  }
  return state.merge({
    ...INITIAL_STATE.toObject(),
    loggedAs: loggedAs,
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [UsersTypes.GET_USER_LOADING]: getUserLoading,
  [UsersTypes.GET_USER_SUCCESS]: getUserSuccess,
  [UsersTypes.GET_USER_FAILURE]: getUserFailure,

  [UsersTypes.GET_USERS_LOADING]: getUsersLoading,
  [UsersTypes.GET_USERS_SUCCESS]: getUsersSuccess,
  [UsersTypes.GET_USERS_FAILURE]: getUsersFailure,

  [UsersTypes.GET_PROJECTS_STATISTIC_LOADING]: getProjectsStatisticLoading,
  [UsersTypes.GET_PROJECTS_STATISTIC_SUCCESS]: getProjectsStatisticSuccess,
  [UsersTypes.GET_PROJECTS_STATISTIC_FAILURE]: getProjectsStatisticFailure,

  [UsersTypes.CHANGE_PASSWORD_LOADING]: changePasswordLoading,
  [UsersTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [UsersTypes.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,

  [UsersTypes.GET_ME_LOADING]: getMeLoading,
  [UsersTypes.GET_ME_SUCCESS]: getMeSuccess,
  [UsersTypes.GET_ME_FAILURE]: getMeFailure,

  [UsersTypes.GET_ACTIVE_USER_LOADING]: getActiveUserLoading,
  [UsersTypes.GET_ACTIVE_USER_SUCCESS]: getActiveUserSuccess,
  [UsersTypes.GET_ACTIVE_USER_FAILURE]: getActiveUserFailure,

  [UsersTypes.CREATE_USER_LOADING]: createUserLoading,
  [UsersTypes.CREATE_USER_SUCCESS]: createUserSuccess,
  [UsersTypes.CREATE_USER_FAILURE]: createUserFailure,

  [UsersTypes.SET_ACTIVE_USER_SUBSCRIPTION]: setActiveUserSubscription,
  [UsersTypes.SET_ACTIVE_USER_GRANTS]: setActiveUserGrants,

  [UsersTypes.LOGIN_LOADING]: loginLoading,
  [UsersTypes.LOGIN_SUCCESS]: loginSuccess,
  [UsersTypes.LOGIN_FAILURE]: loginFailure,

  [UsersTypes.LOGIN_AS_LOADING]: loginAsLoading,
  [UsersTypes.LOGIN_AS_SUCCESS]: loginAsSuccess,
  [UsersTypes.LOGIN_AS_FAILURE]: loginAsFailure,

  [UsersTypes.LOGOUT_LOADING]: logoutLoading,
  [UsersTypes.LOGOUT_SUCCESS]: logoutSuccess,
  [UsersTypes.LOGOUT_FAILURE]: logoutFailure,

  [UsersTypes.REGISTER_LOADING]: registerLoading,
  [UsersTypes.REGISTER_SUCCESS]: registerSuccess,
  [UsersTypes.REGISTER_FAILURE]: registerFailure,

  [UsersTypes.REQUEST_RECOVERY_LOADING]: requestRecoveryLoading,
  [UsersTypes.REQUEST_RECOVERY_SUCCESS]: requestRecoverySuccess,
  [UsersTypes.REQUEST_RECOVERY_FAILURE]: requestRecoveryFailure,

  [UsersTypes.RECOVER_LOADING]: recoverLoading,
  [UsersTypes.RECOVER_SUCCESS]: recoverSuccess,
  [UsersTypes.RECOVER_FAILURE]: recoverFailure,

  [UsersTypes.CONFIRM_EMAIL_LOADING]: confirmEmailLoading,
  [UsersTypes.CONFIRM_EMAIL_SUCCESS]: confirmEmailSuccess,
  [UsersTypes.CONFIRM_EMAIL_FAILURE]: confirmEmailFailure,

  [UsersTypes.SEND_CONFIRM_EMAIL_LOADING]: sendConfirmEmailLoading,
  [UsersTypes.SEND_CONFIRM_EMAIL_SUCCESS]: sendConfirmEmailSuccess,
  [UsersTypes.SEND_CONFIRM_EMAIL_FAILURE]: sendConfirmEmailFailure,

  [UsersTypes.DELETE_USER_LOADING]: deleteUserLoading,
  [UsersTypes.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [UsersTypes.DELETE_USER_FAILURE]: deleteUserFailure,

  [UsersTypes.UPDATE_USER_LOADING]: updateUserLoading,
  [UsersTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [UsersTypes.UPDATE_USER_FAILURE]: updateUserFailure,

  [UsersTypes.SET_ACCEPT_TERMS_FORM_OPEN]: setAcceptTermsFormOpen,

  [UsersTypes.GET_SUBSCRIPTIONS_LOADING]: getSubscriptionsLoading,
  [UsersTypes.GET_SUBSCRIPTIONS_SUCCESS]: getSubscriptionsSuccess,
  [UsersTypes.GET_SUBSCRIPTIONS_FAILURE]: getSubscriptionsFailure,

  [UsersTypes.GET_USER_PERMISSIONS_LOADING]: getUserPermissionsLoading,
  [UsersTypes.GET_USER_PERMISSIONS_SUCCESS]: getUserPermissionsSuccess,
  [UsersTypes.GET_USER_PERMISSIONS_FAILURE]: getUserPermissionsFailure,

  [UsersTypes.TWO_FACTOR_LOGIN_LOADING]: twoFactorLoginLoading,
  [UsersTypes.TWO_FACTOR_LOGIN_SUCCESS]: twoFactorLoginSuccess,
  [UsersTypes.TWO_FACTOR_LOGIN_FAILURE]: twoFactorLoginFailure,

  [UsersTypes.GET_TWO_FACTOR_AUTH_LOADING]: getTwoFactorAuthLoading,
  [UsersTypes.GET_TWO_FACTOR_AUTH_SUCCESS]: getTwoFactorAuthSuccess,
  [UsersTypes.GET_TWO_FACTOR_AUTH_FAILURE]: getTwoFactorAuthFailure,

  [UsersTypes.ACTIVATE_TWO_FACTOR_AUTH_LOADING]: activateTwoFactorAuthLoading,
  [UsersTypes.ACTIVATE_TWO_FACTOR_AUTH_SUCCESS]: activateTwoFactorAuthSuccess,
  [UsersTypes.ACTIVATE_TWO_FACTOR_AUTH_FAILURE]: activateTwoFactorAuthFailure,

  [UsersTypes.DEACTIVATE_TWO_FACTOR_AUTH_LOADING]: deactivateTwoFactorAuthLoading,
  [UsersTypes.DEACTIVATE_TWO_FACTOR_AUTH_SUCCESS]: deactivateTwoFactorAuthSuccess,
  [UsersTypes.DEACTIVATE_TWO_FACTOR_AUTH_FAILURE]: deactivateTwoFactorAuthFailure,

  [UsersTypes.GET_USER_DB_LOGS_LOADING]: getUserDBLogsLoading,
  [UsersTypes.GET_USER_DB_LOGS_SUCCESS]: getUserDBLogsSuccess,
  [UsersTypes.GET_USER_DB_LOGS_FAILURE]: getUserDBLogsFailure,

  [UsersTypes.GET_USER_RELEASES_LOADING]: getUserReleasesLoading,
  [UsersTypes.GET_USER_RELEASES_SUCCESS]: getUserReleasesSuccess,
  [UsersTypes.GET_USER_RELEASES_FAILURE]: getUserReleasesFailure,

  [UsersTypes.SET_USER_SEEN_RELEASES_LOADING]: setUserSeenReleasesLoading,
  [UsersTypes.SET_USER_SEEN_RELEASES_SUCCESS]: setUserSeenReleasesSuccess,
  [UsersTypes.SET_USER_SEEN_RELEASES_FAILURE]: setUserSeenReleasesFailure,

  [UsersTypes.GET_USER_PROJECTS_LOADING]: getUserProjectsLoading,
  [UsersTypes.GET_USER_PROJECTS_SUCCESS]: getUserProjectsSuccess,
  [UsersTypes.GET_USER_PROJECTS_FAILURE]: getUserProjectsFailure,

  [UsersTypes.SET_TWO_FACTOR_LOGIN]: setTwoFactorLogin,

  'persist/REHYDRATE': rehydrate,

  // [UsersTypes.ACTIVATE_OFFLINE_SUBSCRIPTION_SUCCESS]: activateOfflineSubscriptionSuccess,
  // [UsersTypes.DEACTIVATE_OFFLINE_SUBSCRIPTION_SUCCESS]: deactivateOfflineSubscriptionSuccess,
})
