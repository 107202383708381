import axios from './axios'
import { call } from 'redux-saga/effects'
import { toast } from 'react-toastify'
// import { CustomerAction } from '../types/customers'
// import { FeatureAction } from '../types/features'
// import { KeyAction } from '../types/licenseKey'
import { RoverAction } from 'types/rovers'
// import { VendorLicenseAction } from '../types/vendorLicenses'
import { SystemTypeAction } from '../types/systemTypes'

const Messages = {
  /*
  [FeatureAction.ADD_FEATURE]: 'Feature has been created!',
  [FeatureAction.DELETE_FEATURE]: 'Feature has been deleted!',

  [KeyAction.ADD_KEY]: 'Key has been created!',
  [KeyAction.EDIT_KEY]: 'Key has been updated!',
  [KeyAction.DELETE_KEY]: 'Key has been deleted!',

  [CustomerAction.ADD_CUSTOMER]: 'Customer has been created!',
  [CustomerAction.EDIT_CUSTOMER]: 'Customer has been updated!',
  [CustomerAction.DELETE_CUSTOMER]: 'Customer has been deleted!',

  [VendorLicenseAction.ADD_VENDOR_LICENSE]: 'Vendor license has been created!',
  [VendorLicenseAction.DELETE_VENDOR_LICENSE]: 'Vendor license has been deleted!',
  [VendorLicenseAction.ADD_VENDOR_LICENSE_CODE]: 'Vendor license code has been created!',
  */
  [SystemTypeAction.ADD_SYSTEM_TYPE]: 'System type has been successfully created!',
  [SystemTypeAction.UPDATE_SYSTEM_TYPE]: 'System type has been successfully updated!',
  [SystemTypeAction.DELETE_SYSTEM_TYPE]: 'System type has been successfully deleted!',

  [SystemTypeAction.ADD_PROFILE]: 'Profile has been added to the system type!',
  [SystemTypeAction.UPDATE_PROFILE_NAME]: 'Profile name has been successfully updated!',

  [SystemTypeAction.ADD_CONFIGURATION]: 'Configuration to system type has been successfully added!',
  [SystemTypeAction.SET_DEFAULT_PROFILE]: 'Default profile has been successfully changed!',

  [SystemTypeAction.ADD_LINKS]: 'File links have been added!',
  [SystemTypeAction.UPDATE_LINK]: 'File link has been updated!',

  [RoverAction.ADD_ROVER]: 'Rover has been successfully created!',
  [RoverAction.EDIT_CALIBRATION]: 'Rover calibration has been successfully updated!',
  [RoverAction.DELETE_CALIBRATION]: 'Rover calibration has been successfully deleted!',
  [RoverAction.EDIT_ROVER]: 'Rover has been successfully updated!',
  [RoverAction.ENABLE_DOWNLOAD]: 'Downloading SSH has been enabled for the rover',
}

const ErrorMessages = {
  /*
  [FeatureAction.ADD_FEATURE]: 'Error occurred while creating the feature',
  [FeatureAction.DELETE_FEATURE]: 'Error occurred while deleting the feature',

  [KeyAction.ADD_KEY]: 'Error occurred while creating the key',
  [KeyAction.EDIT_KEY]: 'Error occurred while updating the key',
  [KeyAction.DELETE_KEY]: 'Error occurred while deleting the key',

  [CustomerAction.ADD_CUSTOMER]: 'Error occurred while creating the customer',
  [CustomerAction.EDIT_CUSTOMER]: 'Error occurred while updating the customer',
  [CustomerAction.DELETE_CUSTOMER]: 'Error occurred while deleting the customer',

  [VendorLicenseAction.ADD_VENDOR_LICENSE]: 'Error occurred while creating vendor license!',
  [VendorLicenseAction.DELETE_VENDOR_LICENSE]: 'Error occurred while deleting vendor license!',
  [VendorLicenseAction.ADD_VENDOR_LICENSE_CODE]: 'Error occurred while creating vendor license code!',
  */
  [SystemTypeAction.ADD_SYSTEM_TYPE]: 'Error occurred while creating system type!',
  [SystemTypeAction.UPDATE_SYSTEM_TYPE]: 'Error occurred while updating system type!',
  [SystemTypeAction.DELETE_SYSTEM_TYPE]: 'Error occurred while deleting system type!',

  [SystemTypeAction.ADD_PROFILE]: 'Error occurred while adding profile to system type!',
  [SystemTypeAction.UPDATE_PROFILE_NAME]: 'Error occurred while updating profile name!',

  [SystemTypeAction.ADD_CONFIGURATION]: 'Error occurred while adding configuration to system type!',
  [SystemTypeAction.SET_DEFAULT_PROFILE]: 'Error occurred while changing default profile!',

  [SystemTypeAction.ADD_LINKS]: 'Error occurred while adding file links!',
  [SystemTypeAction.UPDATE_LINK]: 'Error occurred while updating file link!',

  [RoverAction.ADD_ROVER]: 'Error occurred while creating the rover',
  [RoverAction.EDIT_CALIBRATION]: 'Error occurred while updating rover calibration',
  [RoverAction.DELETE_CALIBRATION]: 'Error occurred while deleting rover calibration',
  [RoverAction.EDIT_ROVER]: 'Error occurred while updating the rover',
  [RoverAction.ENABLE_DOWNLOAD]: 'Error occurred while enabling downloading rover SSH',
}

const getMessageRecursively = data => {
  if (typeof data === 'object' && data) {
    return Object.values(data).reduce((string, value) => {
      return `${string}\n${getMessageRecursively(value)}`
    }, '')
  }
  return data
}

const getMessage = (action, success, response) => {
  if (!success) {
    if (response.status === 500) return ErrorMessages[action] || 'Request was rejected'
    else {
      const { message, data } = response
      if (typeof message === 'string') {
        return message
      }
      if (typeof data === 'string') {
        return data
      }
      if (typeof data === 'object' && data) {
        return getMessageRecursively(data)
      }
      return message.toString()
      /*
      const { data = {}, message } = response
      if (typeof message === 'string') return message
      return Object.values(data).join('\n')
      */
    }
  }
  return Messages[action] || 'Request was fulfilled'
}

export function * makeRequest (url, method, action, body, notify = true) {
  try {
    const response = yield call(axios.request, {
      method,
      url,
      ...(body && { data: body }),
    })
    const message = getMessage(action, true, response)
    if (message && notify) toast.success(message)
    return response
  } catch (e) {
    const message = getMessage(action, false, e.response)
    if (message) toast.error(message)
    throw new Error(e)
  }
}

export const downloadFile = (name, data, settings) => {
  const { type, target } = settings || { type: 'application/json', target: '_target' }
  const blob = new Blob([data], { type })
  const anchor = document.createElement('a')
  anchor.href = window.URL.createObjectURL(blob)
  anchor.target = target
  anchor.download = name
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}
