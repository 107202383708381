import * as Sentry from '@sentry/browser'
import { merge } from 'lodash'

export const lidarmillCookies = {
  'LIDARMILL_TERMS': 'lidarmill_terms_accepted',
  'LIDARMILL_UPDATE': 'lidarmill_update',
  'LIDARMILL_UPDATE_ACCEPTED': 'lidarmill_update_accepted',
}

export const storageId = {
  version: 'version',
  settings: 'accountsUserSettings',
}

export const Env = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
}
const SENTRY = 'https://24edbc6c181b4b1d873fae68bd96b15a@o368987.ingest.sentry.io/5239201'
const DEV_STRIPE_API_KEY = 'pk_test_0EwcE1YJ8IpVD9nKhNsw7UV4'
const PROD_STRIPE_API_KEY = 'pk_live_k0wQ8EeEKKAJ4NsTU21j5ZS3'
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm:ss'
const UTC_OFFSET = '+00:00'
const COMPONENT_INFO_BASE = 'https://componentinfo.phoenixlidar.com'
const COMPONENT_INFO_API_BASE = 'https://componentinfo.phoenixlidar.com/v2/integrations'
const RESOURCES_API_BASE = 'https://resources.phoenixlidar.com/api/v1'
const UNLEASHED_SO_BASE = 'https://au.unleashedsoftware.com/v2/SalesOrder/Update'

const mergeWithDefaults = settings => {
  return merge(settings, {
    DATETIME_FORMAT,
    DATE_FORMAT,
    TIME_FORMAT,
    UTC_OFFSET,
    SENTRY,
    COMPONENT_INFO_BASE,
    COMPONENT_INFO_API_BASE,
    RESOURCES_API_BASE,
    UNLEASHED_SO_BASE,
  })
}

const envs = {
  local: mergeWithDefaults({
    ENV: Env.LOCAL,
    API_BASE: 'https://api.accounts.develop.phoenixlidar.com',
    API_BASE_CLIENT: 'https://api.accounts.develop.phoenixlidar.com/api/v1/client',
    LIDARMILL_BASE: 'https://app.develop.lidarmill.com',
    LIDARMILL_API_BASE: 'https://api.develop.lidarmill.com',
    LICENSING_API_BASE_CLIENT: 'https://licensing.develop.phoenixlidar.com/api/v1/client',
    HOSTNAME: 'accounts.develop.phoenixlidar.com',
    STRIPE_API_KEY: DEV_STRIPE_API_KEY,
  }),
  dev: mergeWithDefaults({
    ENV: Env.DEVELOPMENT,
    API_BASE: 'https://api.accounts.develop.phoenixlidar.com',
    API_BASE_CLIENT: 'https://api.accounts.develop.phoenixlidar.com/api/v1/client',
    LIDARMILL_BASE: 'https://app.develop.lidarmill.com',
    LIDARMILL_API_BASE: 'https://api.develop.lidarmill.com',
    LICENSING_API_BASE_CLIENT: 'https://licensing.develop.phoenixlidar.com/api/v1/client',
    HOSTNAME: 'accounts.develop.phoenixlidar.com',
    STRIPE_API_KEY: DEV_STRIPE_API_KEY,
  }),
  testing: mergeWithDefaults({
    ENV: Env.DEVELOPMENT,
    API_BASE: 'https://api.accounts.testing.phoenixlidar.com',
    API_BASE_CLIENT: 'https://api.accounts.testing.phoenixlidar.com/api/v1/client',
    LIDARMILL_BASE: 'https://app.testing.lidarmill.com',
    LIDARMILL_API_BASE: 'https://api.testing.lidarmill.com',
    LICENSING_API_BASE_CLIENT: 'https://licensing.develop.phoenixlidar.com/api/v1/client',
    HOSTNAME: 'accounts.testing.phoenixlidar.com',
    STRIPE_API_KEY: DEV_STRIPE_API_KEY,
  }),
  prod: mergeWithDefaults({
    ENV: Env.PRODUCTION,
    API_BASE: 'https://api.accounts.phoenixlidar.com',
    API_BASE_CLIENT: 'https://api.accounts.phoenixlidar.com/api/v1/client',
    LIDARMILL_BASE: 'https://app.lidarmill.com',
    LIDARMILL_API_BASE: 'https://api2.lidarmill.com',
    LICENSING_API_BASE_CLIENT: 'https://licensing.phoenixlidar.com/api/v1/client',
    BAYER_BASE: 'https://bayer.lidarmill.com',
    HOSTNAME: 'accounts.phoenixlidar.com',
    STRIPE_API_KEY: PROD_STRIPE_API_KEY,
  }),
}

const localHostnames = ['localhost', '127.0.0.1']
const developmentHostnames = ['accounts.develop.phoenixlidar.com']
const testingHostnames = ['accounts.testing.phoenixlidar.com']
const productionHostnames = ['accounts.phoenixlidar.com']

const calculateConfig = () => {
  const { hostname } = window.location
  if (localHostnames.indexOf(hostname) > -1) {
    return envs['local']
  } else if (developmentHostnames.indexOf(hostname) > -1) {
    return envs['dev']
  } else if (testingHostnames.indexOf(hostname) > -1) {
    return envs['testing']
  } else if (productionHostnames.indexOf(hostname) > -1) {
    return envs['prod']
  } else {
    console.warn('No matching hostname found. Using local configuration for API Endpoints.')
    return envs['local']
  }
}

const config = calculateConfig()
if (config.SENTRY && config.ENV !== Env.LOCAL) {
  Sentry.init({ dsn: config.SENTRY, environment: config.ENV })
}
export const isProductionEnvironment = () => config.ENV === Env.PRODUCTION
export const isLocalEnvironment = () => config.ENV === Env.LOCAL
export default config
