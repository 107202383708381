import { Map } from 'immutable'
import { getDefaultPagination } from '../utils'

export const INITIAL_STATE = Map({
  systemTypeImages: {},
  addImagesIsLoading: false,
  getImagesIsLoading: {},
  systemTypes: [],
  addSystemIsLoading: false,
  pagination: getDefaultPagination({ page_size: 50, order: 'desc', order_by: 'updated_at' }),
  getSystemTypesIsLoading: false,
  allSystemTypes: [],
  getAllSystemTypesIsLoading: false,

  profiles: {},
  filteredProfiles: {},
  getSystemTypeProfilesIsLoading: {},

  configurations: {},
  getSystemTypeConfigurationsIsLoading: {},

  updateProfilesIsLoading: false,

  expandProfile: null,
  expandConfiguration: null,
  selectedSystemType: null,
  selectSystemTypeWithSearch: true,
  selectSystemTypeIsLoading: false,
  updateSystemTypeIsLoading: false,
  addConfigurationIsLoading: false,
  updateConfigurationIsLoading: false,

  getLogsLoading: false,
  logsPagination: {},
  logs: {},
  getTodoLogsLoading: false,
  todoLogs: {},
  todoLogsPagination: {},
})
