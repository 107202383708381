import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'

import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'

import './style.css'

class JSONEditorComponent extends Component {
  componentDidMount () {
    const { defaultMode = 'tree', defaultModes = ['code', 'tree'], onChange, onEditable, schema } = this.props
    const options = {
      modes: defaultModes,
      mode: defaultMode,
      name: 'root',
      enableSort: false,
      enableTransform: false,
      onEditable,
      schema,
      allowSchemaSuggestions: true,
      onModeChange: (newMode, oldMode) => {
        if (newMode === 'tree' || newMode === 'view') {
          this.jsoneditor.expandAll()
        }
      },
      onChange: () => {
        if (typeof onChange === 'function') {
          try {
            const json = this.jsoneditor.get()
            onChange({ okay: true, json })
          } catch (e) {
            onChange({ okay: false, errorMessage: e.message })
          }
        }
      },
    }

    this.jsoneditor = new JSONEditor(this.container, options)
    this.jsoneditor.set(this.props.json || {})
    if (defaultMode === 'view' || defaultMode === 'tree') {
      this.jsoneditor.expandAll()
    }
  }

  componentWillUnmount () {
    if (this.jsoneditor) {
      this.jsoneditor.destroy()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.json && (!prevProps.json || !equals(prevProps.json, this.props.json))) {
      this.jsoneditor.update(this.props.json)
    }
  }

  render () {
    return (
      <div className="jsoneditor-react-container" ref={elem => { this.container = elem }} />
    )
  }
}

JSONEditorComponent.propTypes = {
  schema: PropTypes.object,
  defaultMode: PropTypes.oneOf(['code', 'tree']),
  defaultModes: PropTypes.oneOf(['code', 'tree']),
  onChange: PropTypes.func,
  onEditable: PropTypes.func,
  json: PropTypes.object,
}

export default JSONEditorComponent
