import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  twoFactorLogin: false,
  twoFactorLoginIsLoading: false,
  tempToken: null,
  users: [],
  plsUsers: [],
  nonCompanyUsers: [],
  projectsStatistic: [],
  user: {},
  passwordNotSet: false,
  qr: {},
  qrIsLoading: {},
  activateTwoFactorAuthIsLoading: {},
  deactivateTwoFactorAuthIsLoading: {},

  activeUser: {},
  loggedAs: null,
  token: null,
  roles: null,
  getMeIsLoading: false,
  getMeErrorMessage: null,
  getActiveUserIsLoading: false,
  getActiveUserErrorMessage: null,
  getUsersIsLoading: false,
  getUsersErrorMessage: null,
  createUserIsLoading: false,
  createUserErrorMessage: null,
  loginIsLoading: false,
  loginErrorMessage: null,
  allowedToLoginAt: null,
  registerIsLoading: false,
  registerErrorMessage: null,
  requestRecoveryIsLoading: false,
  requestRecoveryErrorMessage: null,
  requestRecoveryIsSuccess: false,
  recoverIsLoading: false,
  recoverIsSuccess: false,
  recoverErrorMessage: null,

  companyRegisterIsLoading: false,

  confirmEmailIsSuccess: false,
  confirmEmailIsLoading: false,
  confirmEmailErrorMessage: null,

  sendConfirmEmailIsSuccess: false,
  sendConfirmEmailIsLoading: false,
  sendConfirmEmailErrorMessage: null,

  changeUserActivationIsLoading: null,
  changeUserActivationErrorMessage: null,
  deleteUserIsLoading: null,
  deleteUserErrorMessage: null,

  updateUserIsLoading: null,
  updateUserErrorMessage: null,
  updateUserSucceed: false,

  acceptTermsFormOpen: false,
  systemTypesByUser: {},
  systemTypesByUserLoading: {},

  subscriptionsByUser: {},
  subscriptionsByUserLoading: {},

  permissionsByUser: {},
  permissionsByUserLoading: {},
  loggedUserPermissions: [],

  getUserDBLogsLoading: false,
  getUserDBLogsPagination: {},
  userLogs: {},

  totalReleases: 0,
  releases: [],
  releasesIsLoading: false,

  projectsByUser: {},
  isGetUserProjectsLoading: {},
})
