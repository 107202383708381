// External dependencies.
import React from 'react'
import { PropTypes } from 'prop-types'
import { Trans } from 'react-i18next'
import { equals } from 'ramda'
// Material UI.
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import ListItem from '@material-ui/core/ListItem'
// import ListItemText from '@material-ui/core/ListItemText'
// Icons
// Internal dependencies.
// Local deps
import { isDisabled } from 'utils/templatedForm'
import { getResetValues, callOnChange, valueShouldBeTransformed } from './utils'
import { DataType } from 'types/form'
// Local components
import TabsOption from './Components/TabsOption'
import ImageOption from './Components/ImageOption'
import StringOption from './Components/StringOption'
import BooleanOption from './Components/BooleanOption'
import CheckBoxOption from './Components/CheckboxOption'
import FloatOption from './Components/FloatOption'
import AutocompleteOption from './Components/AutocompleteOption'
import SelectionOption from './Components/SelectionOption'
import DateTimeOption from './Components/DateTimeOption'
import Vector3Option from './Components/Vector3Option'
import IntegerOption from './Components/IntegerOption'
import TextOption from './Components/TextOption'
import MultiFloatOption from './Components/MultiFloatOption'
import DropdownPanelOption from './Components/DropdownPanelOption'
import FileListSelectorOption from './Components/FileListSelectorOption'
import SliderOption from './Components/SliderOption'
import MultiSelectionOption from './Components/MultiSelectionOption'
import DateOption from './Components/DateOption'
import UploadFilesOption from './Components/UploadFilesOption'
import JsonOption from './Components/JsonOption'
import RadioSelectOption from './Components/RadioSelectOption'
import SerialNumberOption from './Components/SerialNumbersOption'
import ComboboxOption from './Components/ComboboxOption'
import LinksOption from './Components/LinksOption'
import MultiStringOption from './Components/MultiStringOption'

const mapTypeToRenderFunction = {
  [DataType.STRING]: StringOption,
  [DataType.BOOLEAN]: BooleanOption,
  [DataType.INTEGER]: IntegerOption,
  [DataType.FLOAT]: FloatOption,
  [DataType.SELECTION]: SelectionOption,
  [DataType.AUTOCOMPLETE]: AutocompleteOption,
  [DataType.VECTOR3]: Vector3Option,
  [DataType.DATETIME]: DateTimeOption,
  [DataType.TABS]: TabsOption,
  [DataType.IMAGE]: ImageOption,
  [DataType.TEXT]: TextOption,
  [DataType.MULTI_FLOAT]: MultiFloatOption,
  [DataType.DROPDOWN_PANEL]: DropdownPanelOption,
  [DataType.FILELIST_SELECTOR]: FileListSelectorOption,
  [DataType.SLIDER]: SliderOption,
  [DataType.MULTIPLE_SELECTION]: MultiSelectionOption,
  [DataType.CHECKBOX]: CheckBoxOption,
  [DataType.DATE]: DateOption,
  [DataType.UPLOAD_FILES]: UploadFilesOption,
  [DataType.JSON]: JsonOption,
  [DataType.RADIO_SELECT]: RadioSelectOption,
  [DataType.SERIAL_NUMBERS]: SerialNumberOption,
  [DataType.COMBOBOX]: ComboboxOption,
  [DataType.FILE_LINKS]: LinksOption,
  [DataType.MULTI_STRING]: MultiStringOption,
}

const renderOption = (name, option, options) => {
  const { dataType } = option
  const { state, extra, values, formTemplate, extraProps } = options

  const disabled = isDisabled({ option, state, extra, values, formTemplate, name, extraProps })
  // Depending on the data type of the options (and whether it has suggestions or not) a different
  // type of input field will be rendered. If the data type was unknown, nothing will be rendered.
  const newOptions = {
    ...options,
    name,
    disabled,
    option,
    renderOption,
  }
  const RenderFunction = mapTypeToRenderFunction[dataType]
  if (RenderFunction) {
    return <RenderFunction {...newOptions} renderOption={renderOption}/>
  }
}

const styles = theme => ({
  ...theme.global,
  field: {
    display: 'flex',
    // alignItems: 'flex-end',
    // justifyContent: 'flex-end',
    flexDirection: 'column',
    margin: 0,
  },
})

class TemplatedForm extends React.Component {
  componentDidMount () {
    const { values } = this.props
    // `onChange` needs to be called with the initial values.
    if (typeof values === 'undefined') {
      callOnChange(this.props, getResetValues(this.props, this.props))
    }
  }

  shouldComponentUpdate (nextProps) {
    return !equals(nextProps, this.props)
  }

  componentDidUpdate (props) {
    // The provided values have to be removed when the user selects another pipeline type
    // with a different job.
    if (!equals(props.extra, this.props.extra)) {
      const newValues = getResetValues(props, this.props)
      // After all values have been reset, `onChange` has to be called.
      callOnChange(this.props, newValues)
    }
  }

  // Fields can have a `transformOnChangeOf` property specified. If the property is specified
  // and a field referenced in the property changed, then the value of that field has to be
  // re-transformed.
  getTransformedValues = (valuesBeforeTransform, state, extra, formTemplate, names, oldValues, extraProps) => {
    return Object.keys(formTemplate).reduce(
      (result, fieldName) => {
        const oldValue = valuesBeforeTransform[fieldName]
        const field = formTemplate[fieldName]
        const { transform, transformOnChangeOf } = field
        // It will contain the first name from the 'transformOnChangeOf' list
        const shouldBeTransformed = valueShouldBeTransformed(transform, transformOnChangeOf, names)
        if (shouldBeTransformed) {
          return {
            ...result,
            [fieldName]: transform(
              oldValue,
              state,
              valuesBeforeTransform,
              extra,
              formTemplate,
              shouldBeTransformed,
              oldValues,
              undefined,
              extraProps,
            ),
          }
        }
        return result
      },
      valuesBeforeTransform,
    )
  }

  // If `transform` was specified on the field the value needs to be transformed before refreshing
  // the state or calling the `onChange` handler. When `transformOnChangeOf` is present as well,
  // then the transformation should not take place.
  getProcessedValue = (value, state, values, extra, formTemplate, option, extraProps) => {
    const { transform: fieldTransform, transformOnChangeOf: fieldTransformOnChangeOf = [] } = option
    const fieldShouldBeTransformed = fieldTransform && !fieldTransformOnChangeOf &&
    Object.keys(formTemplate).find(name => fieldTransformOnChangeOf.indexOf(name) !== -1)
    return fieldShouldBeTransformed
      ? fieldTransform(
        value,
        state,
        values,
        extra,
        formTemplate,
        fieldShouldBeTransformed,
        undefined,
        undefined,
        extraProps,
      )
      : value
  }

  /**
   * Set the multiple values of the template at once
   * This function should be used inside option component when we want to change multiple values
   * based on the other value change and do not call multiple `onChange` functions (that can be a redux-actions)
   * @param name The name of the field.
   * @param value The new value.
   * @param option The field definition.
   */
  setMultipleValues = (values, options) => {
    const { onSetMultipleValues } = this.props
    if (typeof onSetMultipleValues === 'function') onSetMultipleValues(values)
    const { formTemplate, state, extra, extraProps } = this.props
    const changedValuesNames = Object.keys(values)
    const valuesBeforeTransform = changedValuesNames.reduce((allValues, key) => {
      const option = options ? options[key] || formTemplate[key] : formTemplate[key]
      return {
        ...allValues,
        [key]: this.getProcessedValue(
          values[key],
          state,
          allValues,
          extra,
          formTemplate,
          option,
          extraProps,
        ),
      }
    }, this.props.values)

    const valuesAfterTransform = this.getTransformedValues(
      valuesBeforeTransform,
      state,
      extra,
      formTemplate,
      changedValuesNames,
      values,
      extraProps,
    )
    callOnChange(this.props, valuesAfterTransform)
  }

  /**
   * Set the value of a job option.
   * @param name The name of the field.
   * @param value The new value.
   * @param option The field definition.
   */
  setValue = (name, value, option) => {
    const { onSetValue, formTemplate, extra, state, values, extraProps } = this.props
    if (typeof onSetValue === 'function') onSetValue(name, value, option)
    const processedValue = this.getProcessedValue(value, state, values, extra, formTemplate, option, extraProps)
    const valuesBeforeTransform = {
      ...values,
      [name]: processedValue,
    }
    const valuesAfterTransform = this.getTransformedValues(
      valuesBeforeTransform,
      state,
      extra,
      formTemplate,
      [name],
      values,
      extraProps,
    )
    callOnChange(this.props, valuesAfterTransform)
  }

  renderLayoutOption = (options, name, index) => {
    const { classes } = this.props
    const {
      extra,
      formTemplate,
      state,
      values,
    } = options
    const mainOption = formTemplate[name]
    const option = typeof mainOption.altOption === 'function'
      ? mainOption.altOption(state, values, formTemplate, mainOption)
      : mainOption
    let invisible
    // If `option.invisible` is a function it needs to be evaluated...
    if (typeof option.invisible === 'function') {
      invisible = option.invisible(state, values, extra, formTemplate, option, options)
    } else {
      // ...otherwise the raw value can be used.
      invisible = option.invisible
    }
    // Do not render invisible fields.
    if (invisible) return
    const { gridProps = {} } = option
    const newGridProps = typeof gridProps === 'function'
      ? gridProps(state, values, extra, options)
      : gridProps
    return (
      <Grid item xs={12} key={index} className={classes.field} {...newGridProps}>
        {renderOption(name, option, options)}
      </Grid>
    )
  }

  /**
   * Renders the options of a job.
   */
  renderOptions = () => {
    const { state, actions, extra, extraProps, values, formTemplate } = this.props
    // If there are no options then a message should be displayed instead, notifying the user that
    // there is currently nothing to do for this particular job.
    if (!formTemplate) {
      return (
        <Trans i18nKey="options.none" />
      )
    }
    const options = {
      actions,
      state,
      extra,
      extraProps,
      values,
      setValue: this.setValue,
      setMultipleValues: this.setMultipleValues,
      formTemplate,
    }
    // Renders a list of different input fields. Each field will be rendered individually by `renderOption()`.
    const skipTemplate = []
    const formTemplateKeys = Object.keys(formTemplate)
    /**
     * TODO: check if this is a right approach to create column-like layout?
     */
    return formTemplateKeys.map((name, index) => {
      if (skipTemplate.indexOf(name) >= 0) return null
      const mainOption = formTemplate[name]
      const {
        // indicate option container
        containerNumber = -1,
        // column number inside the container
        column = -1,
        // actual column width
        columnWidth = -1,
      } = mainOption
      if (containerNumber < 0) return this.renderLayoutOption(options, name, index)
      if (containerNumber >= 0) {
        const columnWidths = []
        const templatesForContainer = []
        templatesForContainer[column] = []
        templatesForContainer[column].push(name)
        if (columnWidth >= 1) {
          columnWidths[column] = columnWidth
        }

        for (let i = index + 1; i < formTemplateKeys.length; i++) {
          const key = formTemplateKeys[i]
          const mainOption = formTemplate[key]
          const {
            containerNumber: anotherContainerNumber = -1,
            column: anotherColumn = -1,
            columnWidth: anotherColumnWidth = -1,
          } = mainOption
          if (anotherContainerNumber === containerNumber) {
            if (!Array.isArray(templatesForContainer[anotherColumn])) {
              templatesForContainer[anotherColumn] = []
            }
            if (anotherColumnWidth >= 1) {
              columnWidths[anotherColumn] = anotherColumnWidth
            }
            templatesForContainer[anotherColumn].push(key)
            skipTemplate.push(key)
          }
        }
        return (
          <Grid container key={'container-' + index}>
            {
              templatesForContainer.map((arrayOfTemplates, arrayIndex) => (
                <Grid key={'column-' + arrayIndex} container item xs={12} md={columnWidths[arrayIndex]} justify='center'>
                  {
                    arrayOfTemplates.map(key => this.renderLayoutOption(options, key, `${key}-${arrayIndex}-${index}`))
                  }
                </Grid>
              ))
            }
          </Grid>
        )
      }
      return null
    })
    // return Object.keys(formTemplate).map((name, index) => this.renderLayoutOption(options, name, index))
  }

  render () {
    if (typeof this.props.values === 'undefined') {
      return null
    }
    const { className = '' } = this.props
    return (
      <Grid container spacing={1} className={className}>
        {this.renderOptions()}
      </Grid>
    )
  }
}

TemplatedForm.propTypes = {
  className: PropTypes.string,
  extra: PropTypes.any,
  evaluatedSuggestions: PropTypes.array,
  state: PropTypes.object,
  values: PropTypes.object,
  classes: PropTypes.object,
  actions: PropTypes.object,
  extraProps: PropTypes.object,
  formTemplate: PropTypes.object,
  onGetGCP: PropTypes.func,
  onChange: PropTypes.func,
  onSetValue: PropTypes.func,
  onSetContext: PropTypes.func,
  onGetPosition: PropTypes.func,
  onUnsetContext: PropTypes.func,
  onSetMultipleValues: PropTypes.func,
}

TemplatedForm.defaultProps = {
  extraProps: {},
}

export default withStyles(styles)(TemplatedForm)
