/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import { omit, equals, path as pathRamda } from 'ramda'
import { merge } from 'lodash'
import { useResizeDetector } from 'react-resize-detector'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import {
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableSortLabel,
  TableRow,
  IconButton,
  Input,
  TablePagination,
  Toolbar,
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  Fade,
  LinearProgress,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
// Icons
import MenuIcon from '@material-ui/icons/MoreVert'
import AddIcon from '@material-ui/icons/AddBox'
import ClearIcon from '@material-ui/icons/Close'
import SubmitIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FilterIcon from '@material-ui/icons/FilterList'
import ExpandLessIcon from '@material-ui/icons/ExpandMore'
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowRight'
// Project deps
import Combobox from 'components/reusable/Combobox'
import TagsEditDialog from 'components/Licensing/TagsEditDialog'
import SearchTextField from 'components/Licensing/SearchField'
import ConfirmDialog from 'components/Licensing/ConfirmDialog'
import { getSorting, stableSort } from 'utils/sort'
import { copyTextToClipboard } from 'utils/copy'
import Tag from 'components/reusable/Tag'
import Filter from './Filter'
import MultipleFilters from './MultipleFilters'
import { makeUnique } from 'utils/list'

const styles = theme => ({
  sortIcon: {
    color: 'inherit !important',
  },
  sortLabel: {
    color: 'inherit !important',
  },
  stickyCell: {
    // backgroundColor: '#000000 !important'
  },
  toolbar: {
    minHeight: 'auto',
    flexWrap: 'wrap-reverse',
  },
  paginationToolbar: {
    padding: 0,
    minHeight: 40,
    display: 'flex',
    flexWrap: 'wrap',
  },
  paginationSpacer: {
    flex: 0,
  },
  copyButton: {
    marginLeft: theme.spacing(1),
    fontSize: 11,
    backgroundColor: '#dcdcdc',
    padding: '2px 4px',
    opacity: 0,
    minWidth: 'auto',
    borderRadius: 2,
    transition: 'all .22s ease',
    textTransform: 'inherit',
    color: '#555b69',
    '&&:hover': {
      backgroundColor: '#c3c3c3',
    },
  },
  bodyTableCell: {
    '&&:hover': {
      color: '#e74c3c',
      cursor: 'copy',
    },
    '&&:hover .copyButton': {
      opacity: 1,
    },
  },
  resize: {
    marginLeft: 'auto',
    padding: theme.spacing(0, 1),
    flex: '0 0 12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: '25%',
    '&&:hover': {
      cursor: 'col-resize',
    },
  },
})

function getId (searchMapPathToId, path) {
  const searchMapKeys = Object.keys(searchMapPathToId)
  for (let i = searchMapKeys.length - 1; i >= 0; i--) {
    const pathKey = searchMapKeys[i]
    if (path.includes(pathKey)) return pathKey
  }
  return null
}

function getFilterString (filters, columns) {
  return Object.keys(filters).map(columnId => {
    const column = columns.find(column => column.id === columnId)
    const options = filters[columnId]
    if (options.length === 0) {
      return ''
    }
    return `${column.filterId || column.id}=` + options.map(option => option.id).join(',')
  }).filter(Boolean).join('&')
}
/**
 *
 * @param {*} object
 * @param {*} prevPath
 * @param {*} substrings
 * @param {*} searchFields
 * @param {*} searchMapPathToId
 * @param {*} results
 * @param {*} prevParents
 */
function recursiveSearch (
  object,
  prevPath,
  substrings,
  searchFields,
  searchMapPathToId,
  results,
  prevParents,
  pathPrefix,
) {
  if (object && object !== null) {
    Object.keys(object).forEach(key => {
      const path = prevPath + key
      const value = object[key]
      if (typeof value === 'object') {
        if (value !== null) {
          const id = getId(searchMapPathToId, prevPath)
          const idField = id && searchMapPathToId[id]
          const idFieldValue = object[idField]
          const parent = { id, idFieldValue }
          if (value.length) {
            value.forEach(item =>
              recursiveSearch(item, prevPath + key + '.', substrings, searchFields, searchMapPathToId, results,
                [...prevParents, parent],
                pathPrefix,
              ),
            )
          } else {
            recursiveSearch(value, prevPath + key + '.', substrings, searchFields, searchMapPathToId, results,
              [...prevParents, parent],
              pathPrefix,
            )
          }
        }
      }
      if (searchFields.find(field => pathPrefix + '.' + field === path)) {
        const id = getId(searchMapPathToId, path)
        const idField = id && searchMapPathToId[id]
        const idFieldValue = object[idField]
        const okay = substrings.every(substring => (value + '').match(substring))
        if (okay) {
          if (id && idField) {
            prevParents.forEach(({ id: parentId, idFieldValue: parentIdFieldValue }) => {
              if (typeof results[parentId] === 'undefined') results[parentId] = []
              if (results[parentId].indexOf(parentIdFieldValue) < 0) results[parentId].push(parentIdFieldValue)
            })
            if (typeof results[id] === 'undefined') results[id] = []
            if (results[id].indexOf(idFieldValue) < 0) results[id].push(idFieldValue)
          }
        }
      }
    })
  }
  return results
}

const CustomTable = props => {
  const {
    bodyRowProps = {},
    headerRowProps = {},
    headerCellProps = {},
    onEditSubmit = () => {},
    onDeleteRow = () => {},
    onAddRow = () => {},

    pathPrefix = 'root',
    idField = 'id',
    editFields = [],
    editFieldsIdPath = {},
    editFieldsConfirmation = {},
    searchFields = [],
    searchMapPathToId = { [pathPrefix]: 'id' },
    columns,
    data,
    classes,

    customSearch,

    enableSearch = false,
    enablePagination = false,
    paginationPosition = 'top',
    enableRowAdd = false,
    actions,
    enableRowExpand = false,
    enableRowEdit = false,
    enableRowDelete = false,
    // customRowDelete,
    stickyHeader = false,
    disableGutters = false,

    sortBy = 'created_at',
    sortOrder = 'desc',
    defaultPageSize = 100,
    page: propsPage,
    total: propsTotal,

    noBorder = false,
    noDataLabel = 'No data',
    tooltip: propTooltip,
    expandableRow = () => {},
    onRowClick = () => {},
    onExpand = () => {},
    onSearchChange,
    serverSide = false,
    isLoading = false,
    deleteIsLoading = {},
    editIsLoading = {},
    controlsMenu = false,
    additionalControls,
    scrollTo,
    initialSearch = '',
    enableFilter,
    enableResize = false,
    tableWrapperProps = {},
    customToolbarContent,
    filters: filtersFromProps = [],
  } = props
  const { width: containerWidth, ref } = useResizeDetector()
  const TOTAL_WIDTH = containerWidth - 16
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [tagsDialogOpen, setTagsDialogOpen] = useState(false)
  // Search
  const [search, setSearch] = useState(initialSearch)
  const [searchResults, setSearchResults] = useState({})
  // Filtering
  const [filterDialogState, setFilterDialogState] = useState(false)
  const [filterDialog, setFilterDialog] = useState({ open: false, options: [], filtered: [], id: null, type: 'default' })
  const [filters, setFilters] = useState(
    enableFilter
      ? filtersFromProps.reduce((all, column) => ({
        ...all,
        [column.id]: column.defaultFilter || [],
      }), {})
      : columns.reduce((all, column) => ({
        ...all,
        [column.id]: column.defaultFilter || [],
      }), {}),
  )
  const filtersString = getFilterString(filters, enableFilter ? filtersFromProps : columns)
  const [widths, setWidths] = useState(
    columns.reduce((all, column) => ({
      ...all,
      [column.id]: 100 / columns.length / 100,
    }), {}),
  )
  const resizeRow = ({ dataKey, deltaX, lastX, x, nextDataKey }) => {
    setWidths(prevWidths => {
      const percentDelta = deltaX / TOTAL_WIDTH
      const newWidth = prevWidths[dataKey] + percentDelta
      const newNextDataKeyWidth = prevWidths[nextDataKey] - percentDelta
      if (newWidth * TOTAL_WIDTH <= 75 || newNextDataKeyWidth * TOTAL_WIDTH <= 75) {
        return prevWidths
      }
      return {
        ...prevWidths,
        [dataKey]: newWidth,
        [nextDataKey]: newNextDataKeyWidth,
      }
    })
  }
  // Sort states
  const [order, setOrder] = useState(sortOrder)
  const [orderBy, setOrderBy] = useState(sortBy)
  // Expand states
  const [expanded, setExpanded] = useState([])
  // Edit state
  const [editState, setEditState] = useState({
    id: null,
    values: {},
    changedValues: {},
    originalValues: {},
  })
  const { id: editRowId, values: editValues, changedValues: editChangedValues } = editState
  // Pagination
  const [statePage, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize)
  // Body height
  // const [bodyHeight, setBodyHeight] = useState(document.body.clientHeight)
  const page = typeof propsPage === 'number' ? propsPage - 1 : statePage
  const total = typeof propsTotal === 'number' ? propsTotal : data.length

  const [selectedMenuRowId, setSelectedMenuRowId] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const waitUntilReadyToScroll = scrollTo => {
    setTimeout(() => {
      const element = document.getElementById(`${scrollTo}-expanded`)
      if (element) {
        element.scrollIntoView()
      } else {
        waitUntilReadyToScroll(scrollTo)
      }
    }, 250)
  }

  const handleClick = rowId => event => {
    event.preventDefault()
    event.stopPropagation()
    setSelectedMenuRowId(rowId)
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
    setSelectedMenuRowId(null)
  }

  const handleClose = event => {
    event.stopPropagation()
    event.preventDefault()
    closeMenu()
  }

  // Merge default labels with user defined
  const tooltip = merge({
    delete: 'Delete item',
    edit: 'Edit item',
    add: 'Add item',
    submitEdit: 'Apply editing',
    cancelEdit: 'Cancel editing',
  }, propTooltip)

  /*
  useEffect(() => {
    if (stickyHeader) {
      document.body.onresize = () => {
        setBodyHeight(document.body.clientHeight)
      }
    }
    return () => {
      if (stickyHeader) {
        document.body.onresize = null
      }
    }
  }, [stickyHeader])
  */

  useEffect(() => {
    if (!serverSide) handleSearch(search)
  }, [data])

  // Search handlers
  const resetSearch = () => {
    setSearch('')
    setSearchResults({})
    if (typeof onSearchChange === 'function') {
      onSearchChange('', rowsPerPage, 1, orderBy, order, filtersString)
    }
  }
  const onChangeSearch = e => {
    const searchText = e.target.value
    if (typeof onSearchChange === 'function') {
      onSearchChange(searchText, rowsPerPage, 1, orderBy, order, filtersString)
    }
    setSearch(searchText)
    if (statePage !== 0) setPage(0)
  }
  const onTypeEnd = value => {
    handleSearch(value)
  }

  const selectText = node => {
    if (document.selection) { // IE
      const range = document.body.createTextRange()
      range.moveToElementText(node)
      range.select()
    } else if (window.getSelection) {
      const range = document.createRange()
      range.selectNode(node)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
    }
  }

  const onCopyClick = text => e => {
    e.preventDefault()
    e.stopPropagation()
    selectText(e.target)
    copyTextToClipboard(text)
  }

  const handleSearch = value => {
    if (enableSearch) {
      const substrings = value.split(' ')
      const results = {}
      data.forEach(item => recursiveSearch(
        item,
        `${pathPrefix}.`,
        substrings.map(substring => new RegExp(substring, 'i')),
        searchFields,
        searchMapPathToId,
        results,
        [],
        pathPrefix,
      ))
      setSearchResults(results)
    }
  }

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    if (typeof onSearchChange === 'function') {
      onSearchChange(search, rowsPerPage, newPage + 1, orderBy, order, filtersString)
    }
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    if (typeof onSearchChange === 'function') {
      onSearchChange(search, newRowsPerPage, 1, orderBy, order, filtersString)
    }
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  // Expand handlers
  const onExpandRow = id => e => {
    setExpanded([
      ...expanded,
      id.toString(),
    ])
    onExpand(id.toString())
  }
  const onUnexpandRow = id => e => {
    setExpanded(expanded.filter(expandedId => expandedId !== id))
  }

  // Edit handlers
  const onEditRow = (id, row) => e => {
    e.stopPropagation()
    if (editFields && editFields.length > 0) {
      if (Object.keys(editFieldsIdPath).length > 0) {
        const newEditValues = editFields.reduce((allFields, field) => {
          const pathToId = editFieldsIdPath[field]
          const column = columns.find(column => column.id === field)
          const options = column.options || []
          const optionIdField = column.optionId || idField
          return pathToId
            ? { ...allFields, [field]: options.find(item => item[optionIdField] === pathRamda(pathToId, row)) || row[field] }
            : { ...allFields, [field]: row[field] }
        }, {})
        setEditState({
          id,
          originalValues: newEditValues,
          values: newEditValues,
          changedValues: {},
        })
      } else {
        const newEditValues = editFields.reduce((allFields, field) => ({ ...allFields, [field]: row[field] }), {})
        setEditState({
          id,
          originalValues: newEditValues,
          values: newEditValues,
          changedValues: {},
        })
      }
      // setEditValues(editFields.reduce((allFields, field) => ({ ...allFields, [field]: row[field] }), {}))
    } else {
      const newEditValues = columns.reduce((allFields, { edit = false, id }) => edit
        ? ({ ...allFields, [id]: row[id] })
        : allFields,
      {})
      setEditState({
        id,
        originalValues: newEditValues,
        values: newEditValues,
        changedValues: {},
      })
      /*
      setEditValues(columns.reduce((allFields, { edit = false, id }) => edit
        ? ({ ...allFields, [id]: row[id] })
        : allFields
      , {}))
      */
    }
  }
  const onEditField = e => {
    e.stopPropagation()
    const { name, value } = e.target
    setEditState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
      changedValues: {
        ...prevState.changedValues,
        [name]: true,
      },
    }))
  }
  const onEditDateField = name => newDate => {
    setEditState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: newDate,
      },
      changedValues: {
        ...prevState.changedValues,
        [name]: true,
      },
    }))
  }
  const onEditComboboxField = name => value => {
    setEditState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
      changedValues: {
        ...prevState.changedValues,
        [name]: !equals(prevState.originalValues[name], value),
      },
    }))
  }
  const onCloseEditTagsDialog = e => {
    e.stopPropagation()
    setTagsDialogOpen(false)
  }
  const onEditTags = name => tags => {
    // e.stopPropagation()
    setEditState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: tags,
      },
      changedValues: {
        ...prevState.changedValues,
        [name]: true,
      },
    }))
    setTagsDialogOpen(false)
  }
  const onSubmit = () => {
    const changedFields = Object.keys(editChangedValues)
    const values = Object.keys(editValues).reduce((allValues, key) => {
      return changedFields.includes(key) ? { ...allValues, [key]: editValues[key] } : allValues
    }, {})
    onEditSubmit(editRowId, values)
    // editCustomer(editRowId, editValues)
    setEditState({
      values: {},
      id: null,
      changedValues: {},
      originalValues: {},
    })
  }
  const onSubmitEdit = e => {
    e.stopPropagation()
    const changedFields = Object.keys(editChangedValues)
    if (Object.keys(editFieldsConfirmation).some(key => changedFields.includes(key))) {
      setConfirmDialogOpen(true)
      return
    }
    onSubmit()
  }
  const onCancelConfirm = e => {
    e.stopPropagation()
    setConfirmDialogOpen(false)
  }
  const onSubmitConfirm = e => {
    e.stopPropagation()
    setConfirmDialogOpen(false)
    onSubmit()
  }
  const onCancelEdit = e => {
    e.stopPropagation()
    setEditState({
      values: {},
      id: null,
      changedValues: {},
      originalValues: {},
    })
  }

  // Sort handlers
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    const newOrderBy = isDesc ? 'asc' : 'desc'
    setOrder(newOrderBy)
    setOrderBy(property)
    if (typeof onSearchChange === 'function') {
      onSearchChange(search, rowsPerPage, 1, property, newOrderBy, filtersString)
    }
  }
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const isExpandableRowFunction = typeof expandableRow === 'function'

  const columnsNumber = columns.length +
    ((enableRowEdit || enableRowDelete || enableRowAdd || actions || additionalControls) ? 1 : 0) +
    (enableRowExpand ? 1 : 0)

  const path = pathPrefix
  const idsToFilter = searchResults[path]
  const filteredData = serverSide
    ? data
    : search && idsToFilter
      ? data.filter(item => idsToFilter.indexOf(item[idField]) >= 0)
      : (search && !idsToFilter)
        ? []
        : data
  const sortedData = serverSide ? filteredData : stableSort(filteredData, getSorting(order, orderBy))
  const shouldRenderAddButtonOnPreviousCell = enableRowAdd && !enableRowDelete && !enableRowEdit && !actions && !additionalControls
  // defaultFilters.reduce((all, { id, defaultFilter }) => ({ ...all, [id]: defaultFilter.map(f => f.value) }), {}),

  useEffect(() => {
    if (scrollTo && !expanded.includes(scrollTo) && !isLoading) {
      waitUntilReadyToScroll(scrollTo.toString())
      setExpanded([scrollTo.toString()])
      onExpand(scrollTo.toString())
      // Expanded/element to which we need to scroll can be on different page, so find it and update state
      if (enablePagination && !serverSide) {
        const itemIndex = sortedData.findIndex(item => item.id === scrollTo.toString())
        if (itemIndex >= 0) {
          const page = Math.floor(itemIndex / rowsPerPage)
          setPage(page)
        }
      }
    }
    if (!scrollTo) {
      setExpanded([])
    }
  }, [scrollTo, isLoading, sortedData.length])

  const pagination = enablePagination && (
    <TablePagination
      component='div'
      count={total}
      disabled={isLoading}
      rowsPerPageOptions={makeUnique([defaultPageSize, 50, 100, 150, 200]).sort((a, b) => a - b)}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      classes={{
        toolbar: classes.paginationToolbar,
        spacer: classes.paginationSpacer,
      }}
    />
  )
  const rows = (enablePagination && !serverSide
    ? sortedData.slice(statePage * rowsPerPage, statePage * rowsPerPage + rowsPerPage)
    : sortedData
  )
  let rowsNumber = serverSide
    ? total
      ? rows.length
      : 25
    : enablePagination ? rowsPerPage : 10
  rowsNumber = Math.min(rowsPerPage, rowsNumber)
  const rowsToRender = isLoading
    ? Array.apply(null, Array(rowsNumber)).map((_, index) => ({ id: index }))
    : rows
  return (
    <div style={{ position: 'relative', padding: disableGutters ? 0 : 8, height: '100%', overflow: 'auto', ...(enablePagination && paginationPosition === 'bottom' && { display: 'flex', flexDirection: 'column' }) }} {...tableWrapperProps}>
      {filterDialog.open &&
        <Filter
          open={filterDialog.open}
          options={filterDialog.options}
          filtered={filterDialog.filtered}
          type={filterDialog.type}
          onClose={() => setFilterDialog({ open: false })}
          id={filterDialog.id}
          onSubmit={newFilters => {
            setFilters(prevState => ({
              ...prevState,
              [filterDialog.id]: newFilters,
            }))
            const updatedFilters = {
              ...filters,
              [filterDialog.id]: newFilters,
            }
            const filtersString = getFilterString(updatedFilters, columns)
            onSearchChange(search, rowsPerPage, 1, orderBy, order, filtersString)
            setFilterDialog({ open: false })
          }}
        />
      }
      {filterDialogState &&
        <MultipleFilters
          open
          onClose={() => setFilterDialogState(false)}
          filtered={filters}
          defaultFilters={filtersFromProps}
          onSubmit={newFilters => {
            setFilters(newFilters)
            const filtersString = getFilterString(newFilters, filtersFromProps)
            onSearchChange(search, rowsPerPage, 1, orderBy, order, filtersString)
            setFilterDialogState(false)
          }}
        />
      }
      <ConfirmDialog
        title='Confirm changes'
        onCancel={onCancelConfirm}
        onSubmit={onSubmitConfirm}
        open={confirmDialogOpen}
      >
        <Typography>{Object.values(editFieldsConfirmation).map(text => text)}</Typography>
        <Typography>Are you sure you want to apply changes?</Typography>
      </ConfirmDialog>
      {
        ((enablePagination && paginationPosition === 'top') || enableSearch) && (
          <Toolbar disableGutters className={classes.toolbar} style={{ marginTop: 8 }}>
            {pagination}
            {customToolbarContent && typeof customToolbarContent === 'function' && customToolbarContent()}
            { (enableSearch || enableFilter) && (
              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                { enableFilter && (
                  <IconButton onClick={() => setFilterDialogState(true)}>
                    <FilterIcon/>
                  </IconButton>
                )}
                { enableSearch && (
                  <SearchTextField
                    value={search}
                    onChange={onChangeSearch}
                    onReset={resetSearch}
                    onTypeEnd={onTypeEnd}
                    label='Search'
                  />
                )}
              </div>
            )}
            { customSearch && (
              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                {customSearch()}
              </div>
            )}
          </Toolbar>
        )
      }
      <div ref={ref} style={{
        overflowX: 'auto',
        maxHeight: enablePagination ? `calc(100% - 56px)` : 'auto', // bodyHeight - (98 + 8 + 8 + 40)
        marginTop: enablePagination && paginationPosition === 'top' ? 8 : 0,
      }}>
        <Table
          stickyHeader={stickyHeader}
          style={{
            border: !noBorder ? '1px solid #e0e0e0' : '',
            tableLayout: enableResize ? 'fixed' : 'initial',
          }}
          size='small'>
          <TableHead>
            <TableRow {...headerRowProps}>
              { enableRowExpand && <TableCell {...headerCellProps} style={{ width: 50 }}></TableCell> }
              {
                columns.map(({ id, label, sort = true, sortId, show = true, filter = false, filterId, filterType, filterOptions = [], headerCellProps: cellProps = {}, ...other }, colIndex) => {
                  const labelText_ = 'labelText' in other ? other.labelText : label
                  const idSort = serverSide ? id : sortId || id
                  if (!show) {
                    return null
                  }
                  return (
                    <TableCell
                      key={id}
                      {...headerCellProps}
                      {...cellProps}
                      {...enableResize && {
                        style: {
                          width: !show ? '0%' : enableResize ? (widths[id] || (100 / columns.length / 100)) * TOTAL_WIDTH : 'auto',
                        },
                      }}
                    >
                      { colIndex === columns.length - 1 && shouldRenderAddButtonOnPreviousCell ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {
                            sort
                              ? <TableSortLabel
                                active={orderBy === idSort}
                                direction={order}
                                onClick={createSortHandler(idSort)}
                                classes={{
                                  icon: classes.sortIcon,
                                  root: classes.sortLabel,
                                }}
                              >
                                <Tooltip placement='top' title={label}><span>{label}</span></Tooltip>
                              </TableSortLabel>
                              : <Tooltip placement='top' title={label}><span>{label}</span></Tooltip>
                          }
                          <Tooltip title={tooltip.add}>
                            <IconButton color='inherit' onClick={onAddRow}>
                              <AddIcon/>
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                        : <div style={{ display: 'flex', alignItems: 'center' }}>
                          {filter && (
                            <Tooltip title='Filter' placement='top'>
                              <IconButton style={{ color: '#fff' }} size='small' onClick={() => setFilterDialog({
                                open: true,
                                options: filterOptions,
                                id,
                                filtered: filters[id],
                                type: filterType,
                              })}>
                                <FilterIcon/>
                              </IconButton>
                            </Tooltip>
                          )}
                          {sort
                            ? (
                              <TableSortLabel
                                active={orderBy === idSort}
                                direction={order}
                                onClick={createSortHandler(idSort)}
                                style={{
                                  width: `calc(100% - ${8 + (filter ? 30 : 0)}px)`,
                                }}
                                classes={{
                                  icon: classes.sortIcon,
                                  root: classes.sortLabel,
                                }}
                              >
                                <Tooltip placement='top' title={labelText_}>
                                  <Typography noWrap={enableResize}>
                                    {typeof label === 'function' ? label() : label}
                                  </Typography>
                                </Tooltip>
                              </TableSortLabel>
                            )
                            : <Tooltip placement='top' title={labelText_}>
                              <Typography noWrap={enableResize} style={{ width: `calc(100% - ${(filter ? 30 : 0)}px)` }}>
                                {typeof label === 'function' ? label() : label}
                              </Typography>
                            </Tooltip>
                          }
                          {enableResize && colIndex !== columns.length - 1 && <Draggable
                            axis="x"
                            defaultClassName="DragHandle"
                            defaultClassNameDragging="DragHandleActive"
                            onDrag={(event, { deltaX, lastX, x }) => {
                              resizeRow({
                                dataKey: id,
                                deltaX,
                                lastX,
                                x,
                                nextDataKey: (columns[colIndex + 1] || {}).id,
                              })
                            }}
                            position={{ x: 0 }}
                            zIndex={999}
                          >
                            <span className={classes.resize}>|</span>
                          </Draggable>
                          }
                        </div>
                      }
                    </TableCell>
                  )
                })
              }
              { (enableRowDelete || enableRowEdit || enableRowAdd || additionalControls || actions) && !shouldRenderAddButtonOnPreviousCell && (
                <TableCell {...headerCellProps} align='right'>
                  <div style={{ display: 'flex', textAlign: 'right', justifyContent: 'flex-end' }}>
                    { typeof actions === 'function' && actions()}
                    { enableRowAdd && (
                      <Tooltip title={tooltip.add}>
                        <IconButton color='inherit' onClick={onAddRow}>
                          <AddIcon/>
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rowsToRender.length > 0
                ? rowsToRender
                  .map((item, index) => {
                    const timeout = Math.min(100 + index * 100, 400)
                    const { [idField]: idRow, edit = false, delete: deleting = false, filtered = false } = item
                    const rowId = (idRow || '').toString()
                    const isEditableRow = rowId === editRowId
                    const isExpandedRow = expanded.indexOf(rowId) >= 0
                    const rowProps = typeof bodyRowProps === 'function'
                      ? bodyRowProps(item)
                      : bodyRowProps
                    return (
                      <React.Fragment key={serverSide ? index : rowId + '-' + index}>
                        <Fade in timeout={timeout}>
                          <TableRow
                            onClick={
                              enableRowExpand
                                ? isExpandedRow
                                  ? onUnexpandRow(rowId)
                                  : onExpandRow(rowId)
                                : onRowClick(item)
                            }
                            style={{
                              background: filtered ? '#a2e981' : 'inherit',
                            }}
                            className='hovering-table-row'
                            {...rowProps}
                          >
                            { enableRowExpand && <TableCell
                              onClick={
                                isLoading
                                  ? () => {}
                                  : enableRowExpand
                                    ? isExpandedRow
                                      ? onUnexpandRow(rowId)
                                      : onExpandRow(rowId)
                                    : () => {}
                              }
                            >
                              { !isExpandedRow
                                ? <ExpandMoreIcon/>
                                : <ExpandLessIcon/>
                              }
                            </TableCell>
                            }
                            {
                              columns.map((column, index) => {
                                const {
                                  id,
                                  render,
                                  edit = false,
                                  editRenderType,
                                  editRenderProps = {},
                                  options = [],
                                  label,
                                  searchTemplate,
                                  inputDisplayTemplate,
                                  cellProps = {},
                                  cellStyle = {},
                                  copy = true,
                                  copyText,
                                  show = true,
                                  // typographyProps = {},
                                  optionTooltip,
                                  // wordBreak = 'break-word',
                                  isLoading: isLoadingColumn,
                                  loadingRender,
                                  loadingSkeletonStyle = {},
                                } = column
                                // const { noWrap = true } = typographyProps
                                const canCopy = typeof copy === 'function' ? copy : () => copy
                                const value = item[id]
                                const isValueExist = Boolean(value)
                                let renderComponent = null
                                const skeletonStyle = typeof loadingSkeletonStyle === 'function' ? loadingSkeletonStyle() : loadingSkeletonStyle
                                if (isLoading || isLoadingColumn) {
                                  renderComponent = (
                                    loadingRender
                                      ? typeof loadingRender === 'function'
                                        ? loadingRender()
                                        : loadingRender
                                      : <Skeleton style={{ height: 36, ...skeletonStyle }} animation={false}/>
                                  )
                                } else if (isEditableRow && edit) {
                                  if (editRenderType === 'tag-select') {
                                    renderComponent = (
                                      <div>
                                        <TagsEditDialog
                                          open={tagsDialogOpen}
                                          onSubmit={onEditTags(id)}
                                          onClose={onCloseEditTagsDialog}
                                          allTags={options}
                                          tags={editValues[id]}
                                        />
                                        {editValues[id].map(tagName => (<Tag key={tagName} name={tagName}/>))}
                                        <Tooltip title='Edit tags'>
                                          <IconButton onClick={e => {
                                            e.stopPropagation()
                                            setTagsDialogOpen(true)
                                          }}>
                                            <EditIcon/>
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    )
                                  } else if (editRenderType === 'combobox') {
                                    renderComponent = (
                                      <Combobox
                                        fullWidth
                                        items={options}
                                        searchTemplate={searchTemplate}
                                        inputDisplayTemplate={inputDisplayTemplate}
                                        onChange={onEditComboboxField(id)}
                                        value={inputDisplayTemplate(editValues[id])}
                                        selectedItem={editValues[id]}
                                        label={label}
                                        rowHeight={35}
                                        viewLimit={4}
                                        width={350}
                                        optionTooltip={optionTooltip}
                                        margin={true}
                                        showNoItemsText={true}
                                        disablePortal={false}
                                        useDefaultPopper={false}
                                      />
                                    )
                                  } else if (editRenderType === 'date') {
                                    renderComponent = (
                                      <DatePicker
                                        {...editRenderProps}
                                        value={editValues[id]}
                                        onClick={e => { e.preventDefault(); e.stopPropagation() }}
                                        onChange={onEditDateField(id)}
                                      />
                                    )
                                  } else {
                                    renderComponent = <Input
                                      autoFocus
                                      value={editValues[id]}
                                      onChange={onEditField}
                                      onClick={e => e.stopPropagation()}
                                      name={id}
                                    />
                                  }
                                } else {
                                  renderComponent = (
                                    <Typography
                                      // classes={{ root: copy ? classes.bodyTableCell : '' }}
                                      style={{
                                        // ...(filtered && { fontWeight: 'bold' })
                                      }}
                                      // onClick={e => { e.stopPropagation(); e.preventDefault(); }}

                                      noWrap={enableResize}
                                    >
                                      { !canCopy(item)
                                        ? typeof render === 'function'
                                          ? render(item, value)
                                          : value
                                        : <span
                                          className={canCopy(item) ? classes.bodyTableCell : ''}
                                          onClick={e => { e.stopPropagation(); e.preventDefault() }}
                                          onDoubleClick={canCopy(item) && isValueExist ? typeof copyText === 'function'
                                            ? onCopyClick(copyText(value))
                                            : onCopyClick(value)
                                            : () => {}} >
                                          {
                                            typeof render === 'function'
                                              ? render(item, value)
                                              : value
                                          }
                                        </span>
                                      }
                                    </Typography>
                                  )
                                }
                                if (!show) {
                                  return null
                                }
                                return (
                                  <TableCell
                                    key={column + '-' + index}
                                    {...cellProps}
                                    style={{
                                      ...(typeof cellStyle === 'function' && !isLoading ? cellStyle(item, column) : cellStyle),
                                      // wordBreak,
                                    }}
                                    onClick={
                                      isLoading
                                        ? () => {}
                                        : enableRowExpand
                                          ? isExpandedRow
                                            ? onUnexpandRow(rowId)
                                            : onExpandRow(rowId)
                                          : () => {}
                                    }
                                  >
                                    {renderComponent}
                                  </TableCell>
                                )
                              })
                            }
                            { (enableRowDelete || enableRowEdit || additionalControls) && (
                              <TableCell align='right'>
                                {isLoading
                                  ? <div style={{ display: 'flex', textAlign: 'right', justifyContent: 'flex-end' }}>
                                    <Skeleton style={{ height: 36, width: enableRowDelete + enableRowEdit + Boolean(additionalControls) * 35 }} animation={false}/>
                                  </div>
                                  : <div style={{ display: 'flex', textAlign: 'right', justifyContent: 'flex-end' }}>
                                    { rowId === editRowId
                                      ? (
                                        <React.Fragment>
                                          <Tooltip title={tooltip.submitEdit}>
                                            <div>
                                              <IconButton
                                                color='primary'
                                                onClick={onSubmitEdit}
                                                disabled={
                                                  !Object.keys(editChangedValues).length > 0 || !Object.values(editChangedValues).some(value => value)
                                                }
                                              >
                                                <SubmitIcon/>
                                              </IconButton>
                                            </div>
                                          </Tooltip>
                                          <Tooltip title={tooltip.cancelEdit}>
                                            <IconButton onClick={onCancelEdit}>
                                              <ClearIcon/>
                                            </IconButton>
                                          </Tooltip>
                                        </React.Fragment>
                                      )
                                      : (
                                        <React.Fragment>
                                          {
                                            !!(deleteIsLoading[rowId]) || deleting
                                              ? <div><CircularProgress size={14}/>Deleting</div>
                                              : <React.Fragment>
                                                {
                                                  !!(editIsLoading[rowId]) || edit
                                                    ? <div><CircularProgress size={14}/>Applying changes</div>
                                                    : <React.Fragment>
                                                      { (typeof enableRowEdit === 'function' ? enableRowEdit(item) : enableRowEdit) && (
                                                        <Tooltip title={tooltip.edit}>
                                                          <IconButton onClick={onEditRow(rowId, item)}>
                                                            <EditIcon/>
                                                          </IconButton>
                                                        </Tooltip>
                                                      )}
                                                      { controlsMenu
                                                        ? <React.Fragment>
                                                          <IconButton
                                                            aria-controls="menu"
                                                            aria-haspopup="true"
                                                            onClick={handleClick(rowId)}
                                                          >
                                                            <MenuIcon/>
                                                          </IconButton>
                                                          <Menu
                                                            id="simple-menu"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={selectedMenuRowId === rowId}
                                                            onClose={handleClose}
                                                          >
                                                            { typeof additionalControls === 'function' && additionalControls(item, closeMenu)}
                                                            { enableRowDelete && (
                                                              <MenuItem onClick={onDeleteRow(rowId, item)}>
                                                                <ListItemIcon><DeleteIcon/></ListItemIcon>
                                                                {tooltip.delete}
                                                              </MenuItem>
                                                            )}
                                                          </Menu>
                                                        </React.Fragment>
                                                        : <React.Fragment>
                                                          { typeof additionalControls === 'function' && additionalControls(item)}
                                                          { enableRowDelete && (<Tooltip title={tooltip.delete}>
                                                            <IconButton onClick={onDeleteRow(rowId, item)}>
                                                              <DeleteIcon/>
                                                            </IconButton>
                                                          </Tooltip>
                                                          )}
                                                        </React.Fragment>
                                                      }
                                                    </React.Fragment>
                                                }
                                              </React.Fragment>
                                          }
                                        </React.Fragment>
                                      )
                                    }
                                  </div>
                                }
                              </TableCell>
                            )}
                          </TableRow>
                        </Fade>
                        {
                          (isExpandedRow && isExpandableRowFunction) && (
                            <TableRow id={`${rowId}-expanded`}>
                              <TableCell colSpan={columnsNumber} padding='none'>
                                { expandableRow(item, omit([pathPrefix], searchResults)) }
                              </TableCell>
                            </TableRow>
                          )
                        }
                      </React.Fragment>
                    )
                  })
                : <TableRow>
                  <TableCell colSpan={columnsNumber}>
                    {noDataLabel}
                  </TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
      </div>
      {isLoading && <LinearProgress style={{ position: 'absolute', left: 0, bottom: 0, height: 15, width: '100%' }}/>}
      {paginationPosition === 'bottom' && <div style={{
        marginTop: 'auto',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-end',
        height: '48px',
      }}>{pagination}</div>}
    </div>
  )
}

CustomTable.propTypes = {
  tableWrapperProps: PropTypes.object,
  initialSearch: PropTypes.string,
  // Field name that will be used as ID
  idField: PropTypes.string,
  // Structure of the columns
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    headerCellProps: PropTypes.object,
    cellProps: PropTypes.object,
    // Will be called when user wants to display custom content
    render: PropTypes.func,
  })).isRequired,
  // Actual data
  data: PropTypes.array,
  filters: PropTypes.array,
  // Will be applied to the header row
  headerRowProps: PropTypes.object,
  // Will be applied to the every cell of the header row
  headerCellProps: PropTypes.object,
  // Will be called when user apply editing
  onEditSubmit: PropTypes.func,
  // Will be called when user apply deleting
  onDeleteRow: PropTypes.func,
  // Will be called when user apply adding
  onAddRow: PropTypes.func,
  onRowClick: PropTypes.func,

  classes: PropTypes.object,

  // Sort order - asc | desc
  sortOrder: PropTypes.string,
  // field name
  sortBy: PropTypes.string,
  defaultPageSize: PropTypes.number,

  // Prefix that is used to
  pathPrefix: PropTypes.string,
  searchFields: PropTypes.array,
  searchMapPathToId: PropTypes.object,

  // Enable sticky header for table
  stickyHeader: PropTypes.bool,
  disableGutters: PropTypes.bool,
  // If enableRowEdit === true than this array will be used to set the initial edit state
  editFields: PropTypes.array,

  enableFilter: PropTypes.bool,
  enableSearch: PropTypes.bool,
  enablePagination: PropTypes.bool,
  enableRowAdd: PropTypes.bool,
  enableRowExpand: PropTypes.bool,
  enableRowEdit: PropTypes.bool,
  enableRowDelete: PropTypes.bool,

  // Component/string that will be displayed when no data present
  noDataLabel: PropTypes.any,
  // Object that represent the label-tooltips for actions (delete, add, edit)
  tooltip: PropTypes.object,
  // Will be called for every if enableRowExpand is true
  expandableRow: PropTypes.func,
}

export default withStyles(styles)(CustomTable)
