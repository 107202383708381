import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getSystemTypes: ['search', 'pageSize', 'page', 'orderBy', 'order'],
  getSystemTypesLoading: null,
  getSystemTypesSuccess: ['systemTypes', 'pagination'],
  getSystemTypesFailure: ['errorMessage'],

  getAllSystemTypes: null,
  getAllSystemTypesLoading: null,
  getAllSystemTypesSuccess: ['systemTypes'],
  getAllSystemTypesFailure: ['errorMessage'],

  getSystemType: ['systemTypeId'],
  getSystemTypeLoading: ['systemTypeId'],
  getSystemTypeSuccess: ['systemTypeId', 'systemType'],
  getSystemTypeFailure: ['systemTypeId', 'errorMessage'],

  getSystemTypeConfigurations: ['systemTypeId'],
  getSystemTypeConfigurationsLoading: ['systemTypeId'],
  getSystemTypeConfigurationsSuccess: ['systemTypeId', 'configurations'],
  getSystemTypeConfigurationsFailure: ['systemTypeId', 'errorMessage'],

  getSystemTypeProfiles: ['systemTypeId', 'search'],
  getSystemTypeProfilesLoading: ['systemTypeId'],
  getSystemTypeProfilesSuccess: ['systemTypeId', 'profiles', 'filteredProfiles'],
  getSystemTypeProfilesFailure: ['systemTypeId', 'errorMessage'],

  addSystemType: ['formData', 'onSuccess'],
  addSystemTypeLoading: [],
  addSystemTypeSuccess: ['systemType'],
  addSystemTypeFailure: ['errorMessage'],

  saveAsNewSystemType: ['formData', 'profile', 'configuration', 'oldSystemType', 'onSuccess'],
  saveAsNewSystemTypeLoading: [],
  saveAsNewSystemTypeSuccess: ['systemType', 'profile', 'configuration'],
  saveAsNewSystemTypeFailure: ['errorMessage'],

  updateSystemType: ['systemTypeId', 'formData', 'oldSystemType', 'onSuccess'],
  updateSystemTypeLoading: ['systemTypeId'],
  updateSystemTypeSuccess: ['systemTypeId', 'systemType'],
  updateSystemTypeFailure: ['systemTypeId', 'errorMessage'],

  updateConfiguration: ['systemTypeId', 'configurationId', 'formData', 'oldConfiguration', 'onSuccess'],
  updateConfigurationLoading: ['systemTypeId'],
  updateConfigurationSuccess: ['systemTypeId', 'configuration'],
  updateConfigurationFailure: ['systemTypeId', 'errorMessage'],

  updateProfile: ['systemTypeId', 'profileId', 'formData', 'oldProfile', 'onSuccess'],
  updateProfileLoading: ['systemTypeId'],
  updateProfileSuccess: ['systemTypeId', 'profile'],
  updateProfileFailure: ['systemTypeId', 'errorMessage'],

  deleteSystemType: ['systemTypeId'],
  deleteSystemTypeLoading: ['systemTypeId'],
  deleteSystemTypeSuccess: ['systemTypeId'],
  deleteSystemTypeFailure: ['systemTypeId', 'errorMessage'],

  addConfigurationToSystemType: ['formData', 'onSuccess'],
  addConfigurationToSystemTypeLoading: [],
  addConfigurationToSystemTypeSuccess: ['systemTypeId', 'configuration'],
  addConfigurationToSystemTypeFailure: ['errorMessage'],

  addProfileToSystemType: ['formData', 'onSuccess'],
  addProfileToSystemTypeLoading: [],
  addProfileToSystemTypeSuccess: ['systemTypeId', 'profile'],
  addProfileToSystemTypeFailure: ['errorMessage'],

  setDefaultProfile: ['systemTypeId', 'profileId'],
  setDefaultProfileLoading: [],
  setDefaultProfileSuccess: ['systemTypeId', 'profileId'],
  setDefaultProfileFailure: ['errorMessage'],

  getProfile: ['profileId'],
  getProfileLoading: [],
  getProfileSuccess: ['systemTypeId', 'profileId', 'profile'],
  getProfileFailure: ['errorMessage'],

  updateProfileName: ['systemTypeId', 'oldProfileName', 'newProfileName'],
  updateProfileNameLoading: [],
  updateProfileNameSuccess: ['systemTypeId', 'profiles'],
  updateProfileNameFailure: ['errorMessage'],

  addFileLinks: ['profileId', 'formData'],
  updateFileLink: ['profileId', 'fileLinkId', 'formData'],

  getSystemTypeImages: ['systemTypeId'],
  getSystemTypeImagesLoading: ['systemTypeId'],
  getSystemTypeImagesSuccess: ['systemTypeId', 'images'],
  getSystemTypeImagesFailure: ['systemTypeId', 'errorMessage'],

  addSystemTypeImages: ['systemTypeId', 'images', 'onSuccess'],
  addSystemTypeImagesLoading: ['systemTypeId'],
  addSystemTypeImagesSuccess: ['systemTypeId', 'images'],
  addSystemTypeImagesFailure: ['systemTypeId', 'errorMessage'],

  deleteSystemTypeImages: ['systemTypeId', 'images', 'onSuccess'],
  deleteSystemTypeImagesLoading: [],
  deleteSystemTypeImagesSuccess: ['systemTypeId', 'images'],
  deleteSystemTypeImagesFailure: ['errorMessage'],

  setExpandedProfile: ['id'],
  setExpandedConfiguration: ['id'],
  // addFileLinksLoading: [],
  // addFileLinksSuccess: ['profileId', 'fileLinks'],
  // addFileLinksFailure: ['errorMessage'],

  selectSystemType: ['systemTypeId', 'updateSearch'],
  selectSystemTypeLoading: [],
  selectSystemTypeSuccess: ['systemType', 'updateSearch'],
  selectSystemTypeFailure: [],

  addSystemTypeAttachments: ['systemTypeId', 'attachments'],

  getLogs: ['systemTypeId', 'pagination', 'onSuccess'],
  getLogsLoading: ['systemTypeId'],
  getLogsSuccess: ['systemTypeId', 'logs', 'pagination'],
  getLogsFailure: ['systemTypeId'],

  getTodoLogs: ['todoId', 'pagination', 'onSuccess'],
  getTodoLogsLoading: ['todoId'],
  getTodoLogsSuccess: ['todoId', 'logs', 'pagination'],
  getTodoLogsFailure: ['todoId'],

  deselectSystemType: [],
}, { prefix: 'ROVERS/' })

export const SystemTypeTypes = Types
export default Creators
